import { DateTime } from 'luxon';

export const localFormat = 'MM/dd/yyyy';

/**
 * It creates DateTime luxon object from local format date
 * @param value string
 * @returns DateTime
 */
export const fromLocalFormat = (value: string) =>
  DateTime.fromFormat(value, localFormat);

/**
 * comparator function for ISO date strings
 * can be used to sort arrays with date properties
 * @param val1 - ISO date string
 * @param val2 - ISO date string
 * @returns -1 | 0 | 1 depending on date comparison
 *
 * @example
 * ```
 * dateArray.sort(compareDates);
 * ```
 */
export const compareDates = (val1: string, val2: string) => {
  const date1 = DateTime.fromISO(val1);
  const date2 = DateTime.fromISO(val2);
  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  } else return 0;
};

/**
 * determines whether a date occurs before another date
 * @param date1 - ISO date string
 * @param date2 - ISO date string
 * @returns boolean
 */
export const isBeforeDate = (date1: string, date2: string) =>
  compareDates(date1, date2) === -1;

/**
 * determines whether a date occurs after another date
 * @param date1 - ISO date string
 * @param date2 - ISO date string
 * @returns boolean
 */
export const isAfterDate = (date1: string, date2: string) =>
  compareDates(date1, date2) === 1;
