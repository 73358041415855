import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PillIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="29" height="31" viewBox="0 0 29 31">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                stroke="currentColor"
                strokeWidth="2.16"
                d="M4.634 13.25l8.035-8.616c2.22-2.38 5.947-2.51 8.326-.29 2.38 2.218 2.51 5.946.29 8.325l-8.034 8.617c-2.22 2.38-5.947 2.51-8.326.29-2.38-2.218-2.51-5.946-.29-8.325z"
                transform="translate(-39 -658) translate(18.5 640) translate(22.05 20.781)"
              />
              <path
                fill="currentColor"
                d="M8.942 11.377h5.891V23.16h-5.89c-3.254 0-5.891-2.637-5.891-5.89 0-3.254 2.637-5.892 5.89-5.892z"
                transform="translate(-39 -658) translate(18.5 640) translate(22.05 20.781) rotate(-47 8.942 17.268)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
