import { css } from '@emotion/react';
import { useBranding } from '../providers/ClientBrandingProvider';

// Hexidecimal representation of alpha in rgba for progess bars
const ALPHA_HEX = '1a';

export const BRANDING_STYLE_DEFAULTS = {
  primaryColor: '#92278f',
  primaryColorHover: '#772174',
  secondaryColor: '#1f9e97',
  secondaryColorHover: '#187a75',
  memberCardBackground: '#f9f4f9',
  memberCardBorder: '#92278f',
};

export const useBrandingStyles = () => {
  const branding = useBranding();
  const {
    primaryColor,
    primaryColorHover,
    secondaryColor,
    secondaryColorHover,
    memberCardBackground,
    memberCardBorder,
  } = branding || {};

  return css`
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    :root {
      --default-font: 'Montserrat', Helvetica, Arial;

      --font-10: 0.625rem;
      --font-11: 0.6875rem;
      --font-12: 0.75rem;
      --font-13: 0.8125rem;
      --font-14: 0.875rem;
      --font-15: 0.9375rem;
      --font-16: 1rem;
      --font-17: 1.0625rem;
      --font-18: 1.125rem;
      --font-20: 1.25rem;
      --font-22: 1.375rem;
      --font-24: 1.5rem;
      --font-26: 1.625rem;
      --font-28: 1.75rem;

      --primary-color: ${primaryColor || BRANDING_STYLE_DEFAULTS.primaryColor};
      --primary-color-hover: ${primaryColorHover ||
      BRANDING_STYLE_DEFAULTS.primaryColorHover};

      --secondary-color: ${secondaryColor ||
      BRANDING_STYLE_DEFAULTS.secondaryColor};
      --secondary-color-hover: ${secondaryColorHover ||
      BRANDING_STYLE_DEFAULTS.secondaryColorHover};

      --neutral-color-1: #f5f8f9;
      --neutral-color-2: #e1e2e6;
      --neutral-color-3: #bbbbbb;
      --neutral-color-4: #333333;

      --white: #ffffff;
      --black: #000000;
      /* TODO: get Ruchir to rename this in the styleguide */
      --black-100: #303030;
      --charcol-black: #292d33;
      --grey: #cccdce;
      --silver: #b8b8b8;
      --gull-grey: #97a4b5;
      --carnation: #fc5b5a;
      --aqua: #0090a3;

      --mariner: #2570b2;
      --bombay-grey: #b0b1b2;
      --mercury: #e6e6e6;
      --alabaster: #fafafa;
      --boulder: #777777;
      --catskill-white: #ebf6f6;
      --sushi: #6bb536;
      --light-gray: #aaaaaa;
      --light-aqua: #e5f3f5;
      --red: #fa5e60;
      --alert-danger: #c74848;
      --alto: #d4d4d4;

      --member-card-background: ${memberCardBackground ||
      BRANDING_STYLE_DEFAULTS.memberCardBackground};
      --member-card-border: ${memberCardBorder ||
      BRANDING_STYLE_DEFAULTS.memberCardBorder};

      --modal-screen: rgba(48, 48, 48, 0.59);
      --ad-button-radius: 4px;

      --bar-radius: 8px;
      --bar-height: 16px;
      --bar-background-primary: ${(primaryColor ||
        BRANDING_STYLE_DEFAULTS.primaryColor) + ALPHA_HEX};
      --bar-background-secondary: ${(secondaryColor ||
        BRANDING_STYLE_DEFAULTS.secondaryColorHover) + ALPHA_HEX};

      --table-border: #e5e9ee;
      --table-focus-column: #f7fafb;

      --notification-background: rgba(167, 53, 53, 0.07);
      --notification-color: #a73535;

      --light-gray-blue: #a8b2bd;

      --notification-banner: #505050;
    }
  `;
};
