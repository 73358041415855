import { createUseMutationHook } from 'src/services/createQueryHook';
import type { UseMutationFn } from 'src/services/createQueryHook';
import { apiIdentityServiceUrls } from 'src/services/apiUrls';
import { useRequestIdentityService } from 'src/hooks/useRequestIdentityService';

export type ChangePasswordParams = {
  NewPassword: string;
  ConfirmPassword: string;
  CurrentPassword: string;
};
const useMutationFnChangeNumber: UseMutationFn<
  {},
  ChangePasswordParams
> = () => {
  const request = useRequestIdentityService<{}>({
    method: 'POST',
    url: apiIdentityServiceUrls.changePassword,
    withAuthorization: true,
  });
  return ({ variables }) => request({ data: variables });
};

export const useChangePasswordMutation = createUseMutationHook({
  useMutationFn: useMutationFnChangeNumber,
});
