import type { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { useRequest, useRequestWithoutAuth } from './useRequest';
import type { UseRequestConfig } from './useRequest';
import { getRuntimeSettings } from 'src/providers/GetSettings';

/**
 * It returns an async function that allow making a request to member API
 * @param config - AxiosRequestConfig
 * @returns async function for making request to member API with axios
 */
export const useRequestMemberAPI = <TQueryFnData = unknown>(
  config?: UseRequestConfig,
) => {
  const settings = getRuntimeSettings();

  const memberAPIConfig: AxiosRequestConfig = {
    paramsSerializer: qs.stringify,
    baseURL: settings?.REACT_APP_MEMBER_API,
  };

  return useRequest<TQueryFnData>({ ...memberAPIConfig, ...config });
};

/**
 * It returns an async function that allow making a request to member API
 * @param config - AxiosRequestConfig
 * @returns async function for making request to member API with axios
 */
export const useUnauthenticatedRequestMemberAPI = <TQueryFnData = unknown>(
  config?: UseRequestConfig,
) => {
  const settings = getRuntimeSettings();

  const memberAPIConfig: AxiosRequestConfig = {
    paramsSerializer: qs.stringify,
    baseURL: settings?.REACT_APP_MEMBER_API,
  };

  return useRequestWithoutAuth<TQueryFnData>({ ...memberAPIConfig, ...config });
};
