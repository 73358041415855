import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { type Location, useLocation } from 'react-router-dom';

export const LastLocationContext = createContext<Location | null>(null);
export const useLastLocation = () => useContext(LastLocationContext);

export const LastLocationProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const currentLocationRef = useRef(location);

  useEffect(() => {
    if (location !== currentLocationRef.current) {
      setLastLocation(currentLocationRef.current);
      currentLocationRef.current = location;
    }
  }, [location]);

  return (
    <LastLocationContext.Provider value={lastLocation}>
      {children}
    </LastLocationContext.Provider>
  );
};
