import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const ShieldIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="23" viewBox="0 0 18 23" {...props}>
    <title>icons/shield</title>
    <g
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 21.5s8-4 8-10v-7l-8-3-8 3v7c0 6 8 10 8 10z" />
      <path d="m6.5 11 2 2.5 3.5-5" />
    </g>
  </SvgIcon>
);
