import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type ButtonHTMLProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export type IconButtonProps = ButtonHTMLProps;

/**
 * Component to use a button with an icon only.
 * @example
 * ```
  <IconButton onClick={() => handleOnClickRemove(i)}>
    <TrashIcon />
  </IconButton>
 * ```
 */
export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, ...props }, ref) => (
    <IconButtonBase {...props} ref={ref}>
      {children}
    </IconButtonBase>
  ),
);

const disabledStyles = ({ disabled }: IconButtonProps) => {
  if (disabled) {
    return css`
      opacity: 0.3;
      pointer-events: none;
    `;
  }
};

const IconButtonBase = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }

  ${disabledStyles}
`;
