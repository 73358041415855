import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SearchIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="DRX-62850-search-icn"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="DRX-62850_Maximum-8"
          transform="translate(-349.000000, -254.000000)"
          stroke="#9EA9B4"
          strokeWidth="2"
        >
          <g
            id="ui-/-form-/-search-/-drug-name"
            transform="translate(309.000000, 176.000000)"
          >
            <g id="SEARCH" transform="translate(21.000000, 60.000000)">
              <g id="Group" transform="translate(18.000000, 16.500000)">
                <g id="Search" transform="translate(2.766964, 3.000000)">
                  <circle
                    id="Oval"
                    cx="7.11111111"
                    cy="7.11111111"
                    r="7.11111111"
                  ></circle>
                  <line
                    x1="16"
                    y1="16"
                    x2="12.1333333"
                    y2="12.1333333"
                    id="Path"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
