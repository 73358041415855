import { createUseMutationHook } from 'src/services/createQueryHook';
import { apiIdentityServiceUrls } from 'src/services/apiUrls';
import { useRequestIdentityService } from 'src/hooks/useRequestIdentityService';
import { type ApiError } from 'src/types/api';

export type ChangePhoneNumberParams = {
  NewPhoneNumber: string;
  UseVoiceCallVerification?: boolean;
};
export type ChangePhoneNumberResponse = string;
type PhoneNumberError<T extends string> = {
  status: number;
  title?: string;
  traceId: string;
  type: string;
  errors?: Partial<Record<T, string[]>>;
};
export type ChangePhoneNumberError = PhoneNumberError<'NewPhoneNumber'>;

export const useChangePhoneNumberMutation = createUseMutationHook<
  ChangePhoneNumberResponse,
  ChangePhoneNumberParams,
  ApiError<ChangePhoneNumberError>
>({
  useMutationFn: () => {
    const request = useRequestIdentityService<ChangePhoneNumberResponse>({
      method: 'POST',
      url: apiIdentityServiceUrls.changePhoneNumber,
      withAuthorization: true,
    });
    return ({ variables }) => request({ data: variables });
  },
});

export type VerifyPhoneNumberParams = {
  NewPhoneNumber: string;
  VerificationToken: string;
};
export type VerifyPhoneNumberResponse = string;
export type VerifyPhoneNumberError = PhoneNumberError<'InvalidToken'>;

export const useVerifyPhoneNumberMutation = createUseMutationHook<
  VerifyPhoneNumberResponse,
  VerifyPhoneNumberParams,
  ApiError<VerifyPhoneNumberError>
>({
  useMutationFn: () => {
    const request = useRequestIdentityService<VerifyPhoneNumberResponse>({
      method: 'POST',
      url: apiIdentityServiceUrls.verifyPhoneNumber,
      withAuthorization: true,
    });
    return ({ variables }) => request({ data: variables });
  },
});
