import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BannerCloseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="34" height="34" viewBox="0 0 34 34">
      <g fill="none" fillRule="evenodd">
        <rect stroke="#A8B2BD" x=".5" y=".5" width="33" height="33" rx="16.5" />
        <path
          fill="#A8B2BD"
          d="M23 12.714 18.714 17 23 21.286 21.286 23 17 18.714 12.714 23 11 21.286 15.286 17 11 12.714 12.714 11 17 15.286 21.286 11z"
        />
      </g>
    </SvgIcon>
  );
};
