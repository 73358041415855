import type { ThemeOptions as MUIThemeOptions } from '@mui/material/styles';
import { useBranding } from '../providers/ClientBrandingProvider';

export const useThemeOptions = (): MUIThemeOptions => {
  const branding = useBranding();
  const {
    primaryColor,
    primaryColorHover,
    secondaryColor,
    secondaryColorHover,
  } = branding || {};

  return {
    palette: {
      primary: {
        main: primaryColor || '#92278f',
        dark: primaryColorHover || '#92278f',
      },
      secondary: {
        main: secondaryColor || '#1f9e97',
        dark: secondaryColorHover || '#187a75',
      },
    },
    typography: {
      fontFamily: '"Montserrat", Helvetica, Arial',
      fontWeightBold: 600,
      h1: {
        fontSize: '2.06rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1rem',
        fontWeight: 'bolder',
      },
      subtitle1: {
        fontSize: '1.25rem',
      },
    },
  };
};
