import { useState } from 'react';
import styled from '@emotion/styled';
import {
  ListSubheader as MuiListSubHead,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import type {
  HeaderActionLink,
  MenuBenefitCard,
} from 'src/components/Header/types';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { DropdownArrowDownIcon, UserCircleIcon } from 'src/components/Icons';
import { Body1, Body2, Bold } from 'src/components/Typography';
import { Profile } from 'src/types/member';
import { useSelectedMember } from 'src/components/MemberBenefitController';

const ProfileListWrapper = styled(Box)`
  border-right: solid 1px var(--grey);
  width: 200px;
  padding-left: 5px;
`;

const CardListMenuItem = styled(MenuItem)`
  padding: 10px 20px 10px 16px;
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const ListSubheader = styled(MuiListSubHead)`
  font-size: var(--font-14);
`;

interface AccountMenuProps {
  accountName?: string;
  accountOpts?: HeaderActionLink[];
  activePlans: MenuBenefitCard[];
  inactivePlans: MenuBenefitCard[];
}

/**
 * @component AccountMenu - popover menu with plan & profile toggle and account links
 *
 * @prop accountName is username,
 * @prop activePlans - list of MenuBenefitCard objects representing a user's active
 *   associated benefit cards and the profiles (dependents) attached to each card
 * @prop iactivePlans - list of MenuBenefitCard objects representing a user's iactive
 *   associated benefit cards and the profiles (dependents) attached to each card
 */
export const AccountMenu: React.FC<AccountMenuProps> = ({
  accountName = '',
  accountOpts = [],
  activePlans,
  inactivePlans,
}) => {
  const [popoverRef, setpopoverRef] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(popoverRef);

  const [hoveredCard, setHoveredCard] = useState<MenuBenefitCard>(
    activePlans[0] || inactivePlans[0],
  );
  const profilesList = hoveredCard?.profiles || [];

  const { setSelectedMember } = useSelectedMember();

  const navigate = useNavigate();

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setpopoverRef(event.currentTarget);
  };

  const closeMenu = () => {
    setpopoverRef(null);
    setHoveredCard(activePlans[0] || inactivePlans[0]);
  };

  const handleActionLinkClick = (actionLink: HeaderActionLink) => {
    closeMenu();
    if (actionLink?.onClick) {
      actionLink.onClick();
    }
    if (actionLink?.to) {
      navigate(actionLink.to);
    }
  };

  const handleOnProfileChangeClick = (profile: Profile) => {
    closeMenu();
    if (setSelectedMember) {
      setSelectedMember(profile);
    }
  };

  const handleOnCardHover = (card: MenuBenefitCard) => {
    setHoveredCard(card);
  };

  const CardMenuItem: React.FC<{ card: MenuBenefitCard }> = ({ card }) => (
    <CardListMenuItem
      onMouseEnter={() => handleOnCardHover(card)}
      selected={card.cardId === hoveredCard.cardId}
    >
      {card.cardLabel}
    </CardListMenuItem>
  );

  return (
    <>
      <Button
        startIcon={
          <UserCircleIcon
            sx={{
              width: 24,
            }}
          />
        }
        variant="text"
        size="large"
        color="gray"
        endIcon={
          <DropdownArrowDownIcon isOpen={isOpen} sx={{ fontSize: 'inherit' }} />
        }
        onClick={openMenu}
      >
        <Box fontWeight={500}>{accountName}</Box>
      </Button>

      <Menu
        open={isOpen}
        anchorEl={popoverRef}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isOpen && (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              {activePlans.length > 0 && <ListSubheader>Active</ListSubheader>}
              {activePlans.map((card, index) => (
                <CardMenuItem card={card} key={`activeCardList${index}`} />
              ))}
              {inactivePlans.length > 0 && (
                <ListSubheader>Inactive</ListSubheader>
              )}
              {inactivePlans.map((card, index) => (
                <CardMenuItem card={card} key={`inactiveCardList${index}`} />
              ))}
            </Box>
            {profilesList.length > 0 && (
              <ProfileListWrapper>
                <Box
                  sx={{
                    padding: '6px 16px',
                    fontSize: 'var(--font-18)',
                  }}
                >
                  Manage Profiles
                </Box>
                {profilesList.map((profile, index) => {
                  const label = `${profile.FirstName} ${profile.LastName}`;
                  return (
                    <MenuItem
                      key={`profileList${index}`}
                      onClick={() => handleOnProfileChangeClick(profile)}
                    >
                      <Box
                        component={Body1}
                        fontSize="var(--font-18)"
                        textTransform="capitalize"
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        title={label}
                      >
                        {label}
                      </Box>
                    </MenuItem>
                  );
                })}
              </ProfileListWrapper>
            )}
            <Box>
              {accountOpts.map((item, index) => (
                <MenuItem
                  key={`accOpt${index}`}
                  onClick={() => handleActionLinkClick(item)}
                >
                  <Body2>
                    <Bold>{item.label}</Bold>
                  </Body2>
                </MenuItem>
              ))}
            </Box>
          </Box>
        )}
      </Menu>
    </>
  );
};
