import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ArchiveIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="31" height="30" viewBox="0 0 31 30">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M24 11L24 24 6 24 6 11M4 6H26V11H4z"
              transform="translate(-1041 -428) translate(18 408) translate(1023.5 20)"
            />
            <g
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M7.53 0.509L4.03 4.009 0.53 0.509"
                transform="translate(-1041 -428) translate(18 408) translate(1023.5 20) translate(11 15)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
