import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonBase, ButtonStartIcon, ButtonEndIcon } from './ButtonBase';
import type { ButtonBaseCustomProps } from './ButtonBase';
import type { ButtonCustomProps } from './Button';

export type ButtonLinkProps = ButtonBaseCustomProps &
  ButtonCustomProps &
  Pick<LinkProps, 'to' | 'children'>;
const ButtonBaseLink = ButtonBase.withComponent(Link);

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ children, startIcon, endIcon, ...props }, ref) => (
    <ButtonBaseLink {...props} ref={ref}>
      {startIcon && <ButtonStartIcon>{startIcon}</ButtonStartIcon>}
      {children}
      {endIcon && <ButtonEndIcon>{endIcon}</ButtonEndIcon>}
    </ButtonBaseLink>
  ),
);
