import React from 'react';
import { ButtonBase, ButtonStartIcon, ButtonEndIcon } from './ButtonBase';
import type { ButtonBaseProps } from './ButtonBase';

export type ButtonCustomProps = {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

export type ButtonProps = ButtonBaseProps & ButtonCustomProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, startIcon, endIcon, ...props }, ref) => (
    <ButtonBase {...props} ref={ref}>
      {startIcon && <ButtonStartIcon>{startIcon}</ButtonStartIcon>}
      {children}
      {endIcon && <ButtonEndIcon>{endIcon}</ButtonEndIcon>}
    </ButtonBase>
  ),
);
