import styled from '@emotion/styled';

const HBase = styled.h6`
  font-family: var(--default-font);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black-100);
  margin: 0;
`;

const TitleBase = styled(HBase)`
  font-weight: normal;
`;

export const H1 = styled(HBase.withComponent('h1'))`
  font-size: var(--font-24);
`;

export const H2 = styled(HBase.withComponent('h2'))`
  font-size: var(--font-20);
`;

export const H3 = styled(HBase.withComponent('h3'))`
  font-size: var(--font-16);
`;

export const H4 = styled(HBase.withComponent('h4'))`
  font-size: var(--font-14);
`;

export const H5 = styled(HBase.withComponent('h5'))`
  font-size: var(--font-12);
`;

export const H6 = styled(HBase)`
  font-size: var(--font-10);
`;

export const Title1 = styled(TitleBase.withComponent('h1'))`
  font-size: var(--font-28);
`;

export const Title2 = styled(TitleBase.withComponent('h2'))`
  font-size: var(--font-24);
`;

export const Title3 = styled(TitleBase.withComponent('h3'))`
  font-size: var(--font-22);
`;

export const Title4 = styled(TitleBase.withComponent('h4'))`
  font-size: var(--font-20);
`;

export const Title5 = styled(TitleBase.withComponent('h5'))`
  font-size: var(--font-18);
`;

export const Title6 = styled(TitleBase)`
  font-size: var(--font-16);
`;

export const Title7 = styled(TitleBase)`
  font-size: var(--font-14);
`;

export const Title8 = styled(TitleBase)`
  font-weight: 600;
  font-size: var(--font-15);
`;

// TODO: clarify with Ruchir what color this is
export const Title9 = styled(TitleBase)`
  font-weight: 600;
  font-size: var(--font-14);
`;

// TODO: clarify with Ruchir what color this is
export const Title10 = styled(TitleBase)`
  font-size: var(--font-14);
`;
