// Letting typescript know about the Google Tag Manager dataLayer
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface GTMEvent {
  event: string;
  [key: string]: any;
}

/**
 * @function triggerGTMEvent - triggers Google Tag Manager Event
 *
 * @param {event} - name of the event (required)
 * @param {params} - other data to be sent with the event
 *
 * @example
 * ```
 * triggerGTMEvent({
 *   event: 'select_content',
 *   content_type: 'dashboard',
 *   item_id: 'View Claims',
 * });
 * ```
 */
export const triggerGTMEvent = async (params: GTMEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(params);
};

const getContentType = () => {
  // trim preceding `/` from pathname
  const pathname = document.location.pathname.substring(1);
  switch (pathname) {
    case '':
      return 'dashboard';
    default:
      return pathname;
  }
};

/**
 * @function analyticsClickContentEvent - fires analytics click event
 *
 * @param {trackingId} - identifier of what was clicked
 *
 * @example
 * ```
 * analyticsClickContentEvent('login button');
 * ```
 */
export const analyticsClickContentEvent = (trackingId: string) => {
  triggerGTMEvent({
    event: 'select_content',
    content_type: getContentType(),
    item_id: trackingId,
  });
};
