import axios from 'axios';
import * as R from 'ramda';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { isAxiosError, ERROR_PAGE_PATH } from 'src/misc/errors';
import { useAuth } from 'src/components/Auth';
import { useNavigate } from 'react-router-dom';
import {
  AXIOS_TIMEOUT_INTERVAL,
  AXIOS_TIMEOUT_ERROR_CODE,
} from 'src/misc/constants';
import { obfuscateSensitiveData } from '../misc/obfuscateData';

/**
 * @prop withAuthorization - whether to pass auth headers with the request
 * @prop useGlobalErrorHandling -  whether to use the global API error handling code. Pass 'false' to implement custom handling
 */
type RequestConfig = {
  withAuthorization?: boolean;
  useGlobalErrorHandling?: boolean;
};

export type UseRequestConfig = RequestConfig & AxiosRequestConfig;

/**
 * It returns an async function that allow making a request
 * @param config - UseRequestConfig
 * @returns async function for making request with axios
 */
export const useRequest = <TQueryFnData = unknown>({
  withAuthorization = false,
  useGlobalErrorHandling = true,

  ...axiosRequestConfig
}: UseRequestConfig = {}) => {
  const { user, logout, isLoggedIn } = useAuth();
  const { access_token } = user || {};
  const navigate = useNavigate();

  if (withAuthorization && access_token) {
    axiosRequestConfig.headers = {
      ...axiosRequestConfig.headers,
      Authorization: `Bearer ${access_token}`,
    };
  }

  return useCallback(
    async (config?: AxiosRequestConfig) => {
      const axiosConfig = R.mergeDeepLeft(
        { ...config },
        axiosRequestConfig,
      ) as AxiosRequestConfig;
      try {
        // Global logic to handle server API process timeouts - user will be
        // redirected to the general error page upon this time being exceeded
        axiosConfig.timeout = AXIOS_TIMEOUT_INTERVAL;
        const response = await axios.request<TQueryFnData>(axiosConfig);
        const data = response.data;
        handleSuccessResponse(response, axiosConfig);

        return data;
      } catch (error: any) {
        if (isAxiosError(error) && error.code === AXIOS_TIMEOUT_ERROR_CODE) {
          logError(error, axiosConfig);
          navigate(ERROR_PAGE_PATH);
        }
        if (isAxiosError(error) && useGlobalErrorHandling) {
          logError(error, axiosConfig);
          const statusCode = error.response?.status || -1;
          if (statusCode === 401 && !!isLoggedIn) {
            logout();
          } else if (statusCode > 400 || statusCode < 100) {
            navigate(ERROR_PAGE_PATH);
          }
        }
        throw error;
      }
    },
    [axiosRequestConfig, logout, isLoggedIn, navigate, useGlobalErrorHandling],
  );
};

/**
 * It returns an async function that allow making a request
 * @param config - UseRequestConfig
 * @returns async function for making request with axios
 */
export const useRequestWithoutAuth = <TQueryFnData = unknown>({
  useGlobalErrorHandling = true,
  ...axiosRequestConfig
}: UseRequestConfig = {}) => {
  return useCallback(
    async (config?: AxiosRequestConfig) => {
      const axiosConfig = R.mergeDeepLeft(
        { ...config },
        axiosRequestConfig,
      ) as AxiosRequestConfig;

      try {
        // Global logic to handle server API process timeouts - user will be
        // redirected to the general error page upon this time being exceeded

        axiosConfig.timeout = AXIOS_TIMEOUT_INTERVAL;
        const response = await axios.request<TQueryFnData>(axiosConfig);
        const data = response.data;
        handleSuccessResponse(response, axiosConfig);
        return data;
      } catch (error: any) {
        logError(error, axiosConfig);

        if (isAxiosError(error) && error.code === AXIOS_TIMEOUT_ERROR_CODE) {
          throw error;
        }
        if (isAxiosError(error) && useGlobalErrorHandling) {
          const statusCode = error.response?.status || -1;
          if (statusCode === 401 || statusCode >= 400 || statusCode < 100) {
            throw error;
          }
        }
        throw error;
      }
    },
    [axiosRequestConfig, useGlobalErrorHandling],
  );
};

function handleSuccessResponse<TQueryFnData>(
  response: AxiosResponse<TQueryFnData, any>,
  axiosConfig: AxiosRequestConfig<any>,
) {
  const data = response.data as any;

  if (data && (data.ErrorOccurred || data.ResponseText)) {
    console.error('request response', { data, axiosConfig });
    const renderingError = new Error('API Response 200 with no success');
    logError(renderingError, axiosConfig, response.status, data);
  }
}

function logError(
  error: any,
  axiosConfig: AxiosRequestConfig<any>,
  responseStatus: any = null,
  responseData: any = null,
) {
  try {
    let requestBody;
    try {
      requestBody = obfuscateSensitiveData(axiosConfig.data);
    } catch (error) {
      console.error(error);
    }

    newrelic.noticeError(error, {
      request_params: axiosConfig.params,
      request_body: requestBody,
      request_url: axiosConfig.url ?? '',
      request_method: axiosConfig.method ?? '',
      response_message: error?.message ?? '',
      response_status_code: responseStatus ?? error?.response?.status ?? '',
    });
  } catch (error) {
    console.error(error);
    //don't throw error trying to send log
  }
}
