import { useAuth } from '../Auth';
import { DialogAutoLogout } from './DialogAutoLogout';
import { useAutoLogout } from './useAutoLogout';

export const AutoLogout = () => {
  const { showWarning, reset } = useAutoLogout();
  const { logout } = useAuth();
  return (
    <DialogAutoLogout
      open={showWarning}
      onLogout={() => logout()}
      onStayLoggedIn={() => reset()}
    />
  );
};
