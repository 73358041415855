import type { MemberAPIResponse } from 'src/types/api';
import { createUseQueryHook } from 'src/services/createQueryHook';
import type { UseQueryFn } from 'src/services/createQueryHook';
import { apiUrls } from 'src/services/apiUrls';
import { useRequestMemberAPI } from 'src/hooks/useRequestMemberAPI';
import { MemberPersonalRepresentative } from 'src/types/member';
import { generatePath } from 'react-router-dom';

export type GetMemberAccountSettingPersonalRepresentativeVariables = {
  memberCardIdentityId?: string | number;
};

export type GetMemberAccountSettingPersonalRepresentativeResponse =
  MemberAPIResponse<MemberPersonalRepresentative[]>;

const useQueryFnGet: UseQueryFn<
  GetMemberAccountSettingPersonalRepresentativeResponse,
  GetMemberAccountSettingPersonalRepresentativeVariables
> = () => {
  const request =
    useRequestMemberAPI<GetMemberAccountSettingPersonalRepresentativeResponse>({
      method: 'GET',
      withAuthorization: true,
    });
  return ({ variables }) =>
    request({
      url: generatePath(apiUrls.memberAccountSettingPersonalRepresentative, {
        memberCardIdentityId: variables?.memberCardIdentityId?.toString(),
      }),
    });
};

export const useGetMemberAccountSettingPersonalRepresentativeQuery =
  // eslint-disable-next-line
  createUseQueryHook({
    id: 'getMemberAccountSettingPersonalRepresentative',
    useQueryFn: useQueryFnGet,
  });
