import styled from '@emotion/styled';
import { AppBar } from '@mui/material';
import { Link } from 'src/components/Link';
import { Logo } from 'src/components/Logo';

/**
 * AppBar with custom styles
 */
export const HeaderWrapper = styled(AppBar)`
  background-color: var(--white);
  border: 0;
  box-shadow: 0px 0px 0px 0px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

/**
 * Styled link based on react-router-dom to be used for navigation
 */
export const HeaderLink = styled(Link)`
  margin: 4px 32px 4px 0;
`;

export const HeaderLinksWrapper = styled.div`
  text-align: center;
  flex-grow: 2;
`;

export const LogoImg = styled(Logo)`
  width: 200px;
  height: 63px;
  padding: 10px 0;
`;
