import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PillSmallIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="21px" height="21px" viewBox="0 0 21 21">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-645.000000, -607.000000)">
          <g id="Group-3" transform="translate(608.000000, 400.000000)">
            <g id="Group" transform="translate(21.000000, 194.000000)">
              <g id="Group" transform="translate(17.500000, 14.878788)">
                <path
                  d="M4.90909091,4.90909091 L13.0909091,4.90909091 C15.3502558,4.90909091 17.1818182,6.7406533 17.1818182,9 C17.1818182,11.2593467 15.3502558,13.0909091 13.0909091,13.0909091 L4.90909091,13.0909091 C2.64974421,13.0909091 0.818181818,11.2593467 0.818181818,9 C0.818181818,6.7406533 2.64974421,4.90909091 4.90909091,4.90909091 Z"
                  id="Rectangle"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  transform="translate(9.000000, 9.000000) rotate(-47.000000) translate(-9.000000, -9.000000) "
                ></path>
                <path
                  d="M6.21000671,7.90099242 L10.3009158,7.90099242 L10.3009158,7.90099242 L10.3009158,16.0828106 L6.21000671,16.0828106 C3.95066,16.0828106 2.11909762,14.2512482 2.11909762,11.9919015 C2.11909762,9.7325548 3.95066,7.90099242 6.21000671,7.90099242 Z"
                  id="Rectangle-Copy"
                  fill="currentColor"
                  transform="translate(6.210007, 11.991902) rotate(-47.000000) translate(-6.210007, -11.991902) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
