import React from 'react';
import { handleBreakpoints, ResponsiveStyleValue } from '@mui/system';
import styled from '@emotion/styled';
import { css, Theme, WithTheme } from '@emotion/react';
import { getShouldForwardProp } from '../../misc/styles';

export type ButtonBaseCustomProps = {
  variant?: 'text' | 'solid' | 'outlined';
  color?: 'gray' | 'primary' | 'secondary';
  size?: ResponsiveStyleValue<'medium' | 'large' | 'fullWidth'>;
};

type ButtonHTMLProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonBaseProps = ButtonBaseCustomProps & ButtonHTMLProps;

type ButtonStyleProps = Required<ButtonBaseCustomProps> & ButtonHTMLProps;

const sizeStyles = ({ size, theme }: WithTheme<ButtonStyleProps, Theme>) =>
  handleBreakpoints({ theme }, size, (propValue) => {
    if (propValue === 'large') {
      return css`
        font-size: 18px;
        min-width: 150px;
        padding: 13px 24px;
      `;
    }
    return css`
      ${propValue === 'fullWidth' ? 'width: 100%;' : ''}
      font-size: 14px;
      padding: 11px 24px;
    `;
  });

const grayOutlinedColorStyle = css`
  color: var(--black-100);
  border-color: var(--light-gray-blue);
  background-color: transparent;
  &:hover,
  &:focus {
    border-color: #818b96;
    background-color: #f9f9f9;
  }
  &:active {
    border-color: var(--black-100);
    background-color: #f9f9f9;
  }
`;

const grayTextColorStyle = css`
  color: var(--black-100);
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    background-color: #f9f9f9;
  }
`;

const graySolidColorStyle = css`
  color: var(--white);
  background-color: var(--light-gray-blue);
  border-color: var(--light-gray-blue);
  &:hover,
  &:focus,
  &:active {
    background-color: #818b96;
    border-color: #818b96;
  }
`;

const colorStyles = ({ color, variant }: ButtonStyleProps) => {
  const defaultColor = `var(--${color}-color)`;
  const defaultColorHover = `var(--${color}-color-hover)`;

  if (variant === 'outlined') {
    if (color === 'gray') {
      return grayOutlinedColorStyle;
    }
    return css`
      color: ${defaultColor};
      border-color: ${defaultColor};
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        color: var(--white);
        border-color: ${defaultColor};
        background-color: ${defaultColor};
      }
    `;
  }

  if (variant === 'text') {
    if (color === 'gray') {
      return grayTextColorStyle;
    }
    return css`
      color: ${defaultColor};
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        background-color: var(--neutral-color-1);
      }
    `;
  }

  if (color === 'gray') {
    return graySolidColorStyle;
  }

  return css`
    color: var(--white);
    border-color: ${defaultColor};
    background-color: ${defaultColor};
    &:hover,
    &:focus,
    &:active {
      border-color: ${defaultColorHover};
      background-color: ${defaultColorHover};
    }
  `;
};

const disabledStyles = ({ disabled, variant }: ButtonStyleProps) => {
  if (disabled) {
    return css`
      opacity: ${variant === 'solid' ? 0.2 : 0.3};
      pointer-events: none;
    `;
  }
};

const styles = (props: WithTheme<ButtonStyleProps, Theme>) => css`
  border-radius: 4px;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  border: 1px solid transparent;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
  &:focus,
  &:active {
    opacity: 0.8;
  }
  ${sizeStyles(props)}
  ${colorStyles(props)}
  ${disabledStyles(props)}
  transition: ${props.theme.transitions.create([
    'color',
    'opacity',
    'background-color',
    'border-color',
  ])};
`;

export const ButtonBase = styled('button', {
  shouldForwardProp: getShouldForwardProp({
    omitProps: ['color', 'variant', 'size'],
  }),
})<ButtonBaseProps>`
  ${({
    variant = 'solid',
    size = 'medium',
    color = 'primary',
    ...props
  }: WithTheme<ButtonBaseProps, Theme>) => {
    return styles({ variant, size, color, ...props });
  }}
`;

export const ButtonStartIcon = styled.span`
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
`;

export const ButtonEndIcon = styled.span`
  display: inherit;
  margin-right: -4px;
  margin-left: 8px;
`;
