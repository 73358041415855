export const PHARMACY_TYPES = {
  RETAIL: 1,
  RETAIL90: 2,
  SPECIALTY: 3,
  MAIL: 4,
};

// DAW 0 = No member penalty when filling a brand drug if a generic is available.
// DAW 2 = Member penalty (brand drug cost minus generic drug cost) when filling a brand drug if a generic is available.

export const DAW_CODES = {
  WITHOUT_DIFFERENTIAL_PENALTY: 0,
  SUBSTITUTION_NOT_ALLOWED: 1,
  WITH_DIFFERENTIAL_PENALTY: 2,
};

// Strings for matching toggle selections/profile types
export const CARDHOLDER_DESCRIPTION = 'Cardholder';
export const INDIVIDUAL_DESCRIPTION = 'Individual';
export const FAMILY_DESCRIPTION = 'Family';

export const ADULT_LEGAL_AGE = 18;

export const RXSENSE_CUSTOMER_SERVICE_PHONE_NUMBER = '(888) 211-1608';
export const RXSENSE_TECHNICAL_SUPPORT_PHONE_NUMBER = '(888) 211-1608';

export const SINGLECARE_TENANT_ID = Number(
  process.env.REACT_APP_SINGLECARE_TENANT_ID,
);

// Per the clinical department's instructions, retail 90 calculations triple the user's supplied day supply/quantity figures
export const RETAIL_INPUTS_MULTIPLIER = 3;

// Split.io feature flagging treatments (add/remove these as needed)
export const SPLIT_API_DEFAULT_KEY = 'DEFAULT_KEY';
export const SPLIT_TREATMENT_ON = 'on';
export const SPLIT_TREATMENT_OFF = 'off';
export const SPLIT_ENABLE_PORTAL_OUTAGE_BANNER = 'enable_portal_outage_banner';
export const SPLIT_ENABLE_MULTI_TENANT = 'enable_multi_tenant';

// Module routing configs
export const MODULE_CONFIGS = {
  CoverageAndBenefits: 'IsCoverageAndBenefitsModuleEnabled',
  FindPharmacy: 'IsFindPharmacyModuleEnabled',
  Pricing: 'IsPricingModuleEnabled',
  Claims: 'IsClaimsModuleEnabled',
  DrugInteractions: 'IsDrugInteractionsModuleEnabled',
  OrderNewCard: 'IsOrderNewCardModuleEnabled',
  PaperClaims: 'IsPaperClaimsModuleEnabled',
};

// Axios timeout configs
export const AXIOS_TIMEOUT_INTERVAL = 60000;
export const AXIOS_TIMEOUT_ERROR_CODE = 'ECONNABORTED';

// Pharmacy Open Search configs

// note: this URL is meant to be a stop gap and there is only one environment
// for this so an env variable seems to not be needed in thise case
export const PHARMACY_OPEN_SEARCH_EMBEDDED_URL =
  'https://www-rxsense-com.filesusr.com/html/d2e6ec_2da161a8c9ac1b3d53e81e8f402110a6.html';
