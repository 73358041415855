import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const UserIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="20" viewBox="0 0 18 20" {...props}>
    <title>icons/User</title>
    <g
      transform="translate(1 1)"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 18v-2h0a4 4 0 0 0-4-4H4h0a4 4 0 0 0-4 4s0 0 0 0v2" />
      <circle cx="8" cy="4" r="4" />
    </g>
  </SvgIcon>
);
