export enum apiUrls {
  // RxAgile Member API URLs
  memberCardAndGroup = '/api/monolith/dashboard/get-member-card-and-group',
  membersForCardDisplay = '/api/monolith/dashboard/get-members-for-card-display',
  memberDrugInteractions = '/api/monolith/memberdrug/interactions',
  printCard = '/api/monolith/dashboard/print-member-card',
  getPlanCardOrderSettings = '/api/benefits/card-ordering-config',
  memberCardRequest = '/api/benefits/order-card-print',
  registerSignUpInfo = 'api/registration/sign-up-info',
  linkPlan = 'api/registration/link-plan',
  register = '/api/registration/register',
  pharmacyTypesCoverage = '/api/benefits/networks',
  prescriptionDrugListData = '/api/benefits/formulary-uri',
  foreignAndPaperClaimsData = '/api/monolith/claim/member-paper-claim',
  claim = '/api/monolith/claim',
  claimSearch = '/api/monolith/claim/member-search',
  claimUpdate = '/api/monolith/claim/member-claim-update',
  claimSummaryPDF = '/api/monolith/claim/summary-pdf',
  retailPricingByZipCode = '/api/monolith/memberdrug/price-by-zip-code',
  retailPricingByCoordinates = '/api/monolith/memberdrug/price-by-coordinates',
  mailOrderPricing = '/api/monolith/memberdrug/price-at-mail',
  specialtyStatus = '/api/monolith/memberdrug/is-drug-specialty',
  specialtyPricing = '/api/monolith/memberdrug/price-at-specialty',
  defaultPharmacy = '/api/monolith/memberpharmacy/get-default-pharmacy',
  updateDefaultPharmacy = '/api/monolith/memberpharmacy/update-default-pharmacy',
  deleteDefaultPharmacy = '/api/monolith/memberpharmacy/delete-default-pharmacy',
  pharmacySearchByZipCode = '/api/monolith/memberpharmacy/search-by-zip-code',
  pharmacySearchByCoordinates = '/api/monolith/memberpharmacy/search-by-coordinates',
  memberAccountSettingInformation = '/api/monolith/member-account-setting/member-information',
  memberAccountSettingPersonalRepresentative = '/api/monolith/member-account-setting/GetHIPAARepByMemberCardIdentityId/:memberCardIdentityId',
  enabledModules = 'api/monolith/dashboard/get-active-modules',
  memberExperienceConfiguration = '/api/experience/configs',
  environmentSettings = '/api/environment-settings',

  // SingleCare API URLs
  drugStructure = '/druginformation/v2/GetDrugStructureByTenant',
  productData = '/druginformation/v1/GetRawDrugInformation',
}

export enum apiIdentityServiceUrls {
  changePassword = '/api/MemberAccountManagement/change-password',
  changePhoneNumber = '/api/MemberAccountManagement/change-phone-number',
  verifyPhoneNumber = '/api/MemberAccountManagement/verify-phone-number',
  userInfo = '/connect/userinfo',
}

export enum apiRxSenseUrls {
  drugSearchProductNameSuggestion = '/api/drug/search/product-name/es/suggestion-ex',
}

export enum apiSingleCareUrls {
  drugStructure = '/druginformation/v2/GetDrugStructureByTenant',
  drugInformationSearchDrugs = '/druginformation/v2/searchdrugs',
}
