import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LandingFormIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="158" height="157" viewBox="0 0 158 157">
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-1172 -504) translate(1172.5 504)">
            <circle cx="78.5" cy="78.5" r="78.5" fill="#FFF" />
            <g>
              <g fill="currentColor" fillRule="nonzero">
                <path
                  d="M79.61 24.39l-4-4c-.521-.52-1.365-.52-1.886 0L55.057 39.058l-4.39 4.391V1.333C50.667.597 50.07 0 49.333 0h-48C.598 0 0 .597 0 1.333v77.334C0 79.403.597 80 1.333 80h48c.737 0 1.334-.597 1.334-1.333V55.219l10.276-10.276 18.666-18.667c.521-.52.521-1.365 0-1.885zM48 77.334H2.667V2.667H48v43.448l-2.666 2.666V6.667c0-.737-.598-1.334-1.334-1.334H6.667c-.736 0-1.333.597-1.333 1.334v64c0 .736.597 1.333 1.333 1.333H44c.736 0 1.334-.597 1.334-1.333V60.552L48 57.885v19.448zm-8.943-22.276c-.127.128-.227.28-.294.448l-2.667 6.667c-.273.684.059 1.46.743 1.733.318.128.672.128.99 0l4.838-1.936v7.364H8V8h34.667v43.448l-3.61 3.61zm2.571 4.456l-1.901.76.76-1.901 1.141 1.141zm6.763-5.789L44 58.114 41.885 56l3.057-3.057 5.333-5.334L56 41.885 58.115 44l-9.724 9.724zM60 42.114L57.885 40 60 37.885 62.115 40 60 42.115zm4-4L61.884 36l12.782-12.781 2.114 2.114-12.78 12.782zM60 74.667L60 73.333 57.334 73.333 57.334 74.667 56 74.667 56 77.333 57.334 77.333 57.334 78.667 60 78.667 60 77.333 61.334 77.333 61.334 74.667z"
                  transform="translate(44.5 38.5)"
                />
                <path
                  d="M56.625 2.271L59.291 2.271 59.291 4.938 56.625 4.938z"
                  transform="translate(44.5 38.5) rotate(-45 57.958 3.604)"
                />
                <path
                  d="M60.387 2.279L63.054 2.279 63.054 4.946 60.387 4.946z"
                  transform="translate(44.5 38.5) rotate(-45 61.72 3.612)"
                />
                <path
                  d="M60.388 6.043L63.055 6.043 63.055 8.709 60.388 8.709z"
                  transform="translate(44.5 38.5) rotate(-45 61.722 7.376)"
                />
                <path
                  d="M56.624 6.035L59.291 6.035 59.291 8.701 56.624 8.701z"
                  transform="translate(44.5 38.5) rotate(-45 57.958 7.368)"
                />
                <path
                  d="M77.334 53.333h-2.115l1.495-1.494-1.886-1.886-1.495 1.495v-2.115h-2.666v2.115l-1.495-1.495-1.885 1.886 1.494 1.494h-2.114V56h2.114l-1.494 1.495 1.885 1.885 1.495-1.495V60h2.666v-2.115l1.495 1.495 1.885-1.885L75.22 56h2.115v-2.667zm-2.667 2.438l-1.563 1.562h-2.208l-1.563-1.562v-2.208L70.897 52h2.208l1.563 1.563v2.208z"
                  transform="translate(44.5 38.5)"
                />
                <path
                  d="M64.388 16.945L67.054 16.945 67.054 19.612 64.388 19.612z"
                  transform="translate(44.5 38.5) rotate(-45 65.721 18.279)"
                />
                <path
                  d="M20.69 23.333c2.963-1.486 4.159-5.091 2.673-8.053-1.486-2.962-5.092-4.158-8.053-2.672-2.962 1.486-4.159 5.091-2.673 8.053.58 1.155 1.517 2.093 2.672 2.672-2.021 1.01-3.301 3.074-3.309 5.334v2c0 .736.597 1.333 1.334 1.333h9.333c.736 0 1.333-.597 1.333-1.333v-2c-.007-2.26-1.288-4.324-3.31-5.334zM14.668 18c0-1.841 1.492-3.333 3.333-3.333 1.841 0 3.334 1.492 3.334 3.333 0 1.841-1.493 3.333-3.334 3.333-1.84 0-3.333-1.492-3.333-3.333zm6.667 11.333h-6.667v-.666c0-1.841 1.492-3.334 3.333-3.334 1.841 0 3.334 1.493 3.334 3.334v.666zM28 13.333H38.667V16H28zM28 18.667H38.667V21.334000000000003H28zM28 24H38.667V26.667H28zM28 29.333H38.667V32H28zM12 34.667H38.667V37.334H12zM12 40H38.667V42.667H12zM12 45.333H38.667V48H12zM30.667 62.667c-1.36 0-2.709-.237-3.986-.703 2.153-1.48 2.697-4.426 1.216-6.579-1.48-2.152-4.426-2.697-6.578-1.216-2.153 1.481-2.697 4.427-1.216 6.579.327.476.74.889 1.216 1.216-1.277.466-2.626.704-3.985.703-.737 0-1.334.597-1.334 1.333s.597 1.333 1.334 1.333c2.323.003 4.612-.563 6.666-1.649 2.054 1.086 4.343 1.652 6.667 1.65h4v-2.667h-4zm-4.95-3.455c-.153.23-.351.427-.582.579L24 60.564l-1.145-.781c-.948-.633-1.204-1.915-.57-2.863.632-.948 1.914-1.203 2.862-.57.948.633 1.203 1.914.57 2.862z"
                  transform="translate(44.5 38.5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
