import { DateTime } from 'luxon';
import * as R from 'ramda';
import { isNonNullable } from './logical';
import { localFormat } from './date';

/**
 * Converts a DateTime luxon object to our preferred format
 * @param date
 * @returns formatted date string
 */
export const formatDate = (date: DateTime) => date.toFormat(localFormat);

/**
 * Converts an ISO Date String to our preferred format
 * @param isoDateString
 * @returns formatted date string
 */
export const formatISODate = (isoDateString?: string) =>
  isoDateString ? formatDate(DateTime.fromISO(isoDateString)) : '';

/**
 * Converts a JS Date to our preferred format
 * @param jsDate
 * @returns formatted date string
 */
export const formatJSDate = (jsDate?: Date) =>
  jsDate ? formatDate(DateTime.fromJSDate(jsDate)) : '';

const headUpperCase = (value: string) => R.toUpper(R.head(value));
const getTitleCase = R.compose(
  R.join(' '),
  R.map((value: string) => R.concat(headUpperCase(value), R.tail(value))),
  R.split(' '),
  R.toLower,
);

export const titleCase = (value?: string | null) =>
  isNonNullable(value) ? getTitleCase(value) : value;

export const formatPhone = (phoneNumber: string) =>
  phoneNumber
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const format11DigitUSPhoneAs10DigitPhone = (phoneNumber: string) =>
  formatPhone(phoneNumber.slice(2));

export const formatZipCode = (zipCode?: string | null) => {
  if (!isNonNullable(zipCode) || /[A-Za-z]/.test(zipCode)) {
    return zipCode;
  }
  return zipCode.slice(0, 5);
};

const genderMap: Record<string, string> = {
  '0': 'Not Specified',
  '1': 'Male',
  '2': 'Female',
  '3': 'Non-Binary',
};

export const formatGender = (value?: string | null) =>
  value && genderMap[value];

export const formatRemainingAndPaidValues = (x: number) =>
  (Math.round(x * 100) / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
