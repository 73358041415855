import { Box, Typography } from '@mui/material';

export const RequestDeleteEmail = () => {
  return (
    <Box
      margin={2}
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
    >
      <Box maxWidth={700}>
        <Typography variant="h2" marginBottom={2}>
          How can I delete my email address?
        </Typography>
        <Typography variant="body1">
          MyRxPlan uses email for your account login and password reset
          notifications. If you would like to remove your email address from our
          system, please email{' '}
          <Typography variant="body1" component="span">
            <strong>mobileappsupport@rxsense.com</strong>
          </Typography>
          .
        </Typography>

        <Typography variant="body1" marginTop={2}>
          A representative will reach out to you and confirm that your email has
          been deleted from our system. Your prescription benefit data is
          retained by your pharmacy benefit manager or pharmacy benefit
          provider.
        </Typography>
      </Box>
    </Box>
  );
};
