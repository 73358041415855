import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CrossIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="34" height="34" viewBox="0 0 34 34">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-723 -432) translate(402.5 413) translate(320.5 19)">
              <rect
                width="33"
                height="33"
                x=".5"
                y=".5"
                stroke="currentColor"
                rx="16.5"
              />
              <path
                fill="currentColor"
                d="M23 12.714L18.714 17 23 21.286 21.286 23 17 18.714 12.714 23 11 21.286 15.286 17 11 12.714 12.714 11 17 15.286 21.286 11z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
