import type { MemberAPIResponse } from 'src/types/api';
import { apiUrls } from 'src/services/apiUrls';
import { useUnauthenticatedRequestMemberAPI } from 'src/hooks/useRequestMemberAPI';
import { EnvironmentSettings } from '../types/environmentSettings';
import { createUseQueryHook } from '../services/createQueryHook';

function useQueryFnGet() {
  const request = useUnauthenticatedRequestMemberAPI<
    MemberAPIResponse<EnvironmentSettings>
  >({
    method: 'GET',
    url: apiUrls.environmentSettings,
  });

  return () => request();
}

export const useEnvironmentSettingsQuery = createUseQueryHook({
  id: 'getEnvironmentSettings',
  useQueryFn: useQueryFnGet,
});
