import { useEffect, useRef } from 'react';

/**
 * It call function after delay
 * @param callback
 * @param delay
 */
export const useTimeout = (callback: () => void, delay?: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'undefined') {
      return;
    }
    const id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay]);
};
