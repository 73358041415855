import MaterialContainer, {
  type ContainerProps as MaterialContainerProps,
} from '@mui/material/Container';
import styled from '@emotion/styled';

const maxWidth = 1250;

export type LandingPageContainerProps = Omit<
  MaterialContainerProps,
  'maxWidth'
>;
export const ContentPageContainer: React.FC<LandingPageContainerProps> = (
  props,
) => <Container {...props} maxWidth={false} />;

export const Container = styled(MaterialContainer)`
  max-width: ${maxWidth}px;
`;
