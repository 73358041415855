import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
/**
 * @param props accepts any number of style or other attributes accepted in SvgIcon like translate, transform
 * @returns svgIcon arrow-right (>)
 */

export const ArrowRightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="8" height="12" viewBox="0 0 8 12">
      <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="dropdown-arrow"
          transform="translate(2.000000, 6.000000) scale(-1, -1) translate(-2.000000, -6.000000) translate(-6.000000, -2.000000)"
          fillRule="nonzero"
        >
          <g
            id="Dropdown-Arrow"
            transform="translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000) translate(3.000000, 3.000000)"
          >
            <path
              d="M3,7 L8.49956,7 C8.77595,7 9,7.214035 9,7.504684 L9,8.495316 C9,8.774045 8.7692,9 8.49956,9 L2.49532,9 L1.50468,9 C1.22595,9 1,8.769203 1,8.499564 L1,1.500436 C1,1.224053 1.21404,1 1.50468,1 L2.49532,1 C2.77405,1 3,1.230797 3,1.500436 L3,7 Z"
              id="path-1"
              transform="translate(5.000000, 5.000000) rotate(91.000000) translate(-5.000000, -5.000000) "
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
