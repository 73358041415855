import type { MemberAPIResponse } from 'src/types/api';
import type { MemberBenefit } from 'src/types/member';
import { createUseQueryHook } from 'src/services/createQueryHook';
import type { UseQueryFn } from 'src/services/createQueryHook';
import { apiUrls } from 'src/services/apiUrls';
import { useRequestMemberAPI } from 'src/hooks/useRequestMemberAPI';

type GetMemberCardAndGroupResponse = MemberAPIResponse<MemberBenefit[]>;
const useQueryFnGet: UseQueryFn<GetMemberCardAndGroupResponse> = () => {
  const request = useRequestMemberAPI<GetMemberCardAndGroupResponse>({
    method: 'GET',
    url: apiUrls.memberCardAndGroup,
    withAuthorization: true,
  });
  return () => request();
};

export const useGetMemberCardAndGroupQuery = createUseQueryHook({
  id: 'getMemberCardAndGroup',
  useQueryFn: useQueryFnGet,
});
