import { Navigate } from 'react-router-dom';
import React from 'react';
import { useAuth } from './Auth';

export const RedirectIfLoggedInRoute: React.FC<{
  children?: JSX.Element[] | JSX.Element;
}> = ({ children }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
