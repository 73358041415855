import { BroadcastChannel } from 'broadcast-channel';

export type MessageType = 'user-signed-out' | 'user-signed-in';
const name = 'rx-sense/member-portal';
export let broadcastChannel: BroadcastChannel<MessageType> | undefined =
  undefined;

const isTestEnv = process.env.NODE_ENV === 'test';

/**
 * It setup Broadcast Channel between tabs
 */
export const setupBroadcastChannel = () => {
  if (isTestEnv) {
    return;
  }
  broadcastChannel = new BroadcastChannel<MessageType>(name);

  return broadcastChannel;
};
