import { Navigate } from 'react-router-dom';
import { useSelectedCard, useSelectedMember } from './MemberBenefitController';
import { useGetEnabledModulesQuery } from 'src/api/enabledModules';
import { MODULE_CONFIGS } from 'src/misc/constants';
import { enabledModuleQuerySettings } from 'src/misc/enabledModuleQuerySettings';

export type moduleConfig =
  | 'IsCoverageAndBenefitsModuleEnabled'
  | 'IsFindPharmacyModuleEnabled'
  | 'IsPricingModuleEnabled'
  | 'IsClaimsModuleEnabled'
  | 'IsDrugInteractionsModuleEnabled'
  | 'IsOrderNewCardModuleEnabled'
  | 'IsPaperClaimsModuleEnabled';

/**
 * These modules are not enabled or disabled via settings retrieved from the
 * API, but are otherwise enabled except when a member is terminated or inactive
 */
const otherwiseEnabledModules = {
  IsOrderNewCardModuleEnabled: true,
  IsPaperClaimsModuleEnabled: true,
};

export const RedirectIfDisabledRoute: React.FC<{
  module: moduleConfig;
  children?: React.ReactNode;
}> = ({ module, children }) => {
  const { selectedMember } = useSelectedMember();
  const { selectedCard } = useSelectedCard();
  const cardId = selectedCard?.MemberCard?.CardId;
  const { data, isLoading } = useGetEnabledModulesQuery(
    enabledModuleQuerySettings(cardId as number),
  );

  /**
   * Claims Module is enabled for terminated users unless disabled by the
   * member's plan.
   */
  const isClaimsModule = module === MODULE_CONFIGS.Claims;
  if (
    selectedMember &&
    selectedMember.ShowTermedMemberExperience &&
    !isClaimsModule
  ) {
    return <Navigate to="/" replace />;
  }

  const enabledModulesData = { ...data, ...otherwiseEnabledModules };

  // Confirm that module config API request has completed before performing
  // component rendering or redirects
  if (!isLoading && enabledModulesData && !enabledModulesData[module]) {
    return <Navigate to="/" replace />;
  } else if (!isLoading) {
    return <>{children}</>;
  }

  return <></>;
};
