import Box from '@mui/material/Box';
import { QRCodeSVG } from 'qrcode.react';
import { H2 } from '../../Typography';

export type AppQRCodeProps = {
  title?: React.ReactNode;
  appLink: string;
};

export const AppQRCode: React.FC<AppQRCodeProps> = ({ title, appLink }) => {
  return (
    <>
      {title && (
        <Box
          component={H2}
          color="var(--primary-color)"
          fontWeight={600}
          mb="16px"
        >
          {title}
        </Box>
      )}
      <Box>
        <QRCodeSVG
          value={appLink}
          size={140}
          fgColor="var(--black-100)"
          level="H"
        />
      </Box>
    </>
  );
};
