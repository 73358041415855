import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { SecureRoute } from './components/SecureRoute';
import { RedirectIfLoggedInRoute } from './components/RedirectIfLoggedInRoute';
import { RedirectIfDisabledRoute } from './components/RedirectIfDisabledRoute';
import { LastLocationProvider } from './components/LastLocationProvider';
import { BenefitAndMemberDataProvider } from './components/MemberBenefitController';
import { RequestDeleteEmail } from './components/RequestDeleteEmail';
import { MemberInformationsProvider } from './providers/MemberInformationsProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { ERROR_PAGE_PATH } from './misc/errors';
import { moduleConfig } from './components/RedirectIfDisabledRoute';
import { MODULE_CONFIGS } from './misc/constants';
import { useBranding } from './providers/ClientBrandingProvider';
import { DELETE_MAIL_ROUTE } from './components/RequestDeleteEmail/consts';

const DashboardPage = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const LoginPage = React.lazy(() => import('./pages/Login'));
const RegisterRoot = React.lazy(
  () => import('./pages/Registration/RegisterRoot'),
);
const RegistrationStep1 = React.lazy(
  () => import('./pages/Registration/RegistrationStep1'),
);
const RegistrationStep2 = React.lazy(
  () => import('./pages/Registration/RegistrationStep2'),
);
const CoverageBenefitsPage = React.lazy(
  () => import('./pages/CoverageBenefits'),
);
const OrderNewCardPage = React.lazy(() => import('./pages/OrderNewCard'));
const DrugInteractionsPage = React.lazy(
  () => import('./pages/DrugInteractions'),
);

export const drugPricingDetailsPath = '/drug-pricing/:drugName';
export type DrugPricingDetailsPageParams = {
  drugName: string;
};
const DrugPricingPage = React.lazy(() => import('./pages/DrugPricing'));

const AccountSettingsPage = React.lazy(() => import('./pages/AccountSettings'));

const PrivacyPolicyPage = React.lazy(
  () => import('./pages/Legal/PrivacyPolicy'),
);
const TermsOfServicePage = React.lazy(
  () => import('./pages/Legal/TermsOfService'),
);
const HIPAAPage = React.lazy(() => import('./pages/Legal/HIPAA'));
const FAQsPage = React.lazy(() => import('./pages/Legal/FAQs'));
const ForeignPaperClaimsPage = React.lazy(
  () => import('./pages/ForeignPaperClaims'),
);
const SupportPage = React.lazy(() => import('./pages/Support'));
const ErrorPage = React.lazy(() => import('./pages/Error'));
const errorRoutes = ['*', ERROR_PAGE_PATH];

export const claimsHistoryPath = '/claims-history';
const ClaimsHistoryPage = React.lazy(() => import('./pages/ClaimsHistory'));
export const claimDetailsPath = `${claimsHistoryPath}/:claimID`;
export type ClaimDetailsPageParams = {
  claimID: string;
};
const ClaimDetailsPage = React.lazy(() => import('./pages/ClaimDetails'));
const PharmacySearchPage = React.lazy(() => import('./pages/PharmacySearch'));
const PharmacyOpenSearchPage = React.lazy(
  () => import('./pages/PharmacyOpenSearch'),
);

export const Router = () => {
  const brandingData = useBranding();

  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <LastLocationProvider>
          <BenefitAndMemberDataProvider>
            <MemberInformationsProvider>
              <ErrorBoundary FallbackComponent={ErrorPage}>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    {errorRoutes.map((path) => (
                      <Route path={path} element={<ErrorPage />} key={path} />
                    ))}
                    <Route
                      path="/login"
                      element={
                        <RedirectIfLoggedInRoute>
                          <LoginPage />
                        </RedirectIfLoggedInRoute>
                      }
                    />
                    <Route
                      path="/privacy-policy"
                      element={<PrivacyPolicyPage />}
                    />
                    <Route
                      path="/terms-and-conditions"
                      element={<TermsOfServicePage />}
                    />
                    <Route path="/hipaa-policy" element={<HIPAAPage />} />
                    <Route path="/faqs" element={<FAQsPage />} />
                    <Route path="/support" element={<SupportPage />} />
                    {brandingData?.pharmacyOpenSearchIsEnabled && (
                      <Route
                        path="/pharmacy-open-search"
                        element={<PharmacyOpenSearchPage />}
                      />
                    )}
                    <Route path="/" element={<SecureRoute />}>
                      <Route index element={<DashboardPage />} />
                      <Route
                        path="/settings"
                        element={<AccountSettingsPage />}
                      />
                      <Route
                        path={claimDetailsPath}
                        element={<ClaimDetailsPage />}
                      />
                      <Route
                        path={claimsHistoryPath}
                        element={
                          <RedirectIfDisabledRoute
                            module={MODULE_CONFIGS.Claims as moduleConfig}
                          >
                            <ClaimsHistoryPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                      <Route
                        path="/coverage-and-benefits"
                        element={
                          <RedirectIfDisabledRoute
                            module={
                              MODULE_CONFIGS.CoverageAndBenefits as moduleConfig
                            }
                          >
                            <CoverageBenefitsPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                      <Route
                        path="/order-new-card"
                        element={
                          <RedirectIfDisabledRoute
                            module={MODULE_CONFIGS.OrderNewCard as moduleConfig}
                          >
                            <OrderNewCardPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                      <Route
                        path="/pharmacy-search"
                        element={
                          <RedirectIfDisabledRoute
                            module={MODULE_CONFIGS.FindPharmacy as moduleConfig}
                          >
                            <PharmacySearchPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                      <Route
                        path="/drug-interactions"
                        element={
                          <RedirectIfDisabledRoute
                            module={
                              MODULE_CONFIGS.DrugInteractions as moduleConfig
                            }
                          >
                            <DrugInteractionsPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                      <Route
                        path="/paper-claims"
                        element={
                          <RedirectIfDisabledRoute
                            module={MODULE_CONFIGS.PaperClaims as moduleConfig}
                          >
                            <ForeignPaperClaimsPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                      <Route
                        path={drugPricingDetailsPath}
                        element={
                          <RedirectIfDisabledRoute
                            module={MODULE_CONFIGS.Pricing as moduleConfig}
                          >
                            <DrugPricingPage />
                          </RedirectIfDisabledRoute>
                        }
                      />
                    </Route>
                    <Route path="/register" element={<RegisterRoot />}>
                      <Route
                        path="/register/create-account"
                        element={<RegistrationStep2 />}
                      />
                      <Route path="/register" element={<RegistrationStep1 />} />
                    </Route>
                    <Route
                      path="/data-safety/request-delete-account"
                      element={<RequestDeleteEmail />}
                    />
                  </Route>
                </Routes>
              </ErrorBoundary>
            </MemberInformationsProvider>
          </BenefitAndMemberDataProvider>
        </LastLocationProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export const PublicRouter = () => {
  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <Routes>
          <Route path={DELETE_MAIL_ROUTE} element={<RequestDeleteEmail />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
