import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const RxInfoIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="21" height="23" viewBox="0 0 21 23">
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <g>
            <path
              d="M3.222 21.482h12.89c1.786 0 3.222-1.443 3.222-3.222V3.222c0-1.78-1.436-3.222-3.222-3.222H3.222C1.45 0 0 1.443 0 3.222V18.26c0 1.78 1.45 3.222 3.222 3.222zm13.964-3.222c0 .594-.479 1.074-1.074 1.074H3.222c-.582 0-1.074-.48-1.074-1.074V3.222c0-.594.492-1.074 1.074-1.074h12.89c.595 0 1.074.48 1.074 1.074V18.26zM5.37 5.37c-.583 0-1.075.48-1.075 1.075 0 .592.492 1.074 1.075 1.074h8.593c.595 0 1.074-.482 1.074-1.074 0-.594-.48-1.074-1.074-1.074H5.37zm-1.075 5.371c0-.593.492-1.074 1.075-1.074h8.593c.595 0 1.074.481 1.074 1.074 0 .593-.48 1.074-1.074 1.074H5.37c-.583 0-1.075-.481-1.075-1.074zm1.075 3.223c-.583 0-1.075.48-1.075 1.074 0 .594.492 1.074 1.075 1.074h8.593c.595 0 1.074-.48 1.074-1.074 0-.593-.48-1.074-1.074-1.074H5.37z"
              transform="translate(-43 -296) translate(43.8 296.7)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
