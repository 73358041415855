import * as R from 'ramda';

/**
 * It checks if input is not nullable
 * @param input
 * @returns boolean
 */
export const isNonNullable = <T>(input: null | undefined | T): input is T => {
  return input !== null && input !== undefined;
};

/**
 * It checks if input is nullable
 * @param input
 * @returns boolean
 */
export const isNullable = <T>(
  input: null | undefined | T,
): input is null | undefined => {
  return !isNonNullable(input);
};

/**
 * It checks if input is string
 * @param input
 * @returns boolean
 */
export const isString = <T>(input: string | T): input is string =>
  R.is(String, input);
