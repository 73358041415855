import styled from '@emotion/styled';

export * from './H';
export * from 'src/components/Link';

export const Bold = styled.span`
  font-weight: 600;
`;

const BodyTextBase = styled.p`
  font-family: var(--default-font);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  color: var(--black-100);
`;

export const Body1 = styled(BodyTextBase)`
  font-size: var(--font-17);
`;

export const Body2 = styled(BodyTextBase)`
  font-size: var(--font-16);
`;

export const Body3 = styled(BodyTextBase)`
  font-size: var(--font-15);
`;

export const Body4 = styled(BodyTextBase)`
  font-size: var(--font-14);
`;

export const Body5 = styled(BodyTextBase)`
  font-size: var(--font-13);
`;

export const Eyebrow = styled(BodyTextBase)`
  font-size: var(--font-11);
`;

export const SmallText = styled(BodyTextBase)`
  font-size: var(--font-10);
`;
