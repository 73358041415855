import MaterialContainer, {
  type ContainerProps as MaterialContainerProps,
} from '@mui/material/Container';
import styled from '@emotion/styled';

const maxWidth = 1308;

export type LandingPageContainerProps = Omit<
  MaterialContainerProps,
  'maxWidth'
>;
export const LandingPageContainer: React.FC<LandingPageContainerProps> = (
  props,
) => <Container {...props} maxWidth={false} />;

export const Container = styled(MaterialContainer)`
  max-width: ${maxWidth}px;
`;

export const LandingPageContainerLeft = styled(LandingPageContainer)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${maxWidth / 2}px;
    margin-right: 0;
    padding-right: 0;
  }
`;

export const LandingPageContainerRight = styled(LandingPageContainer)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${maxWidth / 2}px;
    margin-left: 0;
    padding-left: 0;
  }
`;
