import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const TrashIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <title>icons/trash</title>
    <g
      id="icons/trash"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="trash-2"
        transform="translate(3.000000, 2.000000)"
        stroke="currentColor"
        strokeWidth="2"
      >
        <polyline id="Path" points="0 4 2 4 18 4"></polyline>
        <path
          d="M16,4 L16,18 C16,19.1045695 15.1045695,20 14,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,4 M5,4 L5,2 C5,0.8954305 5.8954305,0 7,0 L11,0 C12.1045695,0 13,0.8954305 13,2 L13,4"
          id="Shape"
        ></path>
        <line x1="7" y1="9" x2="7" y2="15" id="Path"></line>
        <line x1="11" y1="9" x2="11" y2="15" id="Path"></line>
      </g>
    </g>
  </SvgIcon>
);
