import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AlertIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="25" height="25" viewBox="0 0 25 25">
      <g
        id="icons/alert_icn_red"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="XMLID_349_-Copy-2"
          fill="currentColor"
          fillRule="nonzero"
          points="13.5456531 14.6387873 11.4068658 14.6387873 11.4068658 5.8934998 13.5456531 5.8934998"
        ></polygon>
        <path
          d="M12.5,0.65 C15.7670894,0.65 18.7294449,1.97923801 20.8751342,4.12491508 C23.0208272,6.27059584 24.3500713,9.23293861 24.3500713,12.5 C24.3500713,15.7670528 23.0208164,18.7293882 20.875125,20.8750674 C18.729426,23.0207541 15.7670641,24.35 12.5,24.35 C9.23294203,24.35 6.27060204,23.0207587 4.12492165,20.8750784 C1.97924125,18.729398 0.65,15.767058 0.65,12.5 C0.65,9.23294203 1.97924125,6.27060204 4.12492165,4.12492165 C6.27060204,1.97924125 9.23294203,0.65 12.5,0.65 Z"
          id="XMLID_349_-path"
          stroke="currentColor"
          strokeWidth="1.3"
          fillRule="nonzero"
        ></path>
        <polygon
          id="XMLID_349_-path"
          fill="currentColor"
          fillRule="nonzero"
          points="13.5456531 19.1065002 11.4068658 19.1065002 11.4068658 16.825127 13.5456531 16.825127"
        ></polygon>
      </g>
    </SvgIcon>
  );
};
