import { createContext, useContext, useState } from 'react';
import { MemberInfoCardType } from './types';

type MemberCardContextType = {
  memberCardInfo?: MemberInfoCardType;
  setMemberCardInfo: (MemberData: MemberInfoCardType) => void;
};

const defaultRegisterContextValue = {
  memberCardInfo: {
    cardholderId: '',
    memberCardId: 0,
    groupNumber: '',
    firstName: '',
    lastName: '',
    dob: '',
  },
  setMemberCardInfo: () => ({}),
};

export const RegisterContext = createContext<MemberCardContextType>(
  defaultRegisterContextValue,
);

export const useMemberCardContext = () => useContext(RegisterContext);

export const RegisterContextProvider: React.FC = ({ children }) => {
  const [memberCardInfo, setMemberCardInfo] = useState<MemberInfoCardType>();
  return (
    <RegisterContext.Provider value={{ memberCardInfo, setMemberCardInfo }}>
      {children}
    </RegisterContext.Provider>
  );
};
