import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Using Mock Service Worker to providing mocks on the network level
// more information: https://mswjs.io/
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
    quiet: true,
  });
}

newrelic.setApplicationVersion(process.env.REACT_APP_BUILD_VERSION ?? '');
newrelic.addRelease(
  'memberportal_release',
  process.env.REACT_APP_BUILD_VERSION ?? '',
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
