import { createContext, useContext } from 'react';
import { ClientBranding } from 'src/types/branding';
import { LoaderMessage } from 'src/components/Loader';
import {
  EnvironmentSettings,
  RuntimeEnvironmentSettings,
} from '../types/environmentSettings';
import {
  getClientBrandingId,
  getContentfulClient,
} from '../services/fetchClientBranding';
import { useClientBrandingQuery } from '../queries/useClientBrandingQuery';

export const BrandingContext = createContext<ClientBranding | null>(null);
export const useBranding = () => useContext(BrandingContext);

export type useGetClientBrandingResponse = {
  fields: ClientBranding;
} | null;
// Fetch base client assets & branding
export const getClientBrandingFn = async (
  settings: EnvironmentSettings,
  runtimeSettings: RuntimeEnvironmentSettings,
): Promise<useGetClientBrandingResponse> => {
  const clientBrandingId = getClientBrandingId();
  const client = getContentfulClient(settings, runtimeSettings);
  if (client) {
    try {
      const entry = await client.getEntry(clientBrandingId);
      return entry;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

const ClientBrandingProvider: React.FC = ({ children }) => {
  const { error, data } = useClientBrandingQuery();

  if (data) {
    // Set portal favicon if present in client Contentful entry
    if (data.fields.favicon) {
      const faviconEl = document.getElementById('favicon');
      faviconEl?.setAttribute('href', data.fields.favicon.fields.file.url);
    }

    return (
      <BrandingContext.Provider value={data.fields}>
        {children}
      </BrandingContext.Provider>
    );
  } else if (error) {
    return (
      <div>
        <h1>Error: Branding could not be retrieved!</h1>
      </div>
    );
  } else {
    return <LoaderMessage />;
  }
};

export default ClientBrandingProvider;
