import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="currentColor" strokeWidth="2">
          <path d="M11 14L5 8 11 2" />
        </g>
      </g>
    </SvgIcon>
  );
};
