import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const UserCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 24a11.922 11.922 0 0 1-8.485-3.515A11.922 11.922 0 0 1 0 12c0-3.205 1.248-6.219 3.515-8.485A11.922 11.922 0 0 1 12 0c3.205 0 6.219 1.248 8.485 3.515A11.922 11.922 0 0 1 24 12c0 3.205-1.248 6.219-3.515 8.485A11.922 11.922 0 0 1 12 24zm0-22.125C6.417 1.875 1.875 6.417 1.875 12S6.417 22.125 12 22.125 22.125 17.583 22.125 12 17.583 1.875 12 1.875zm-.047 11.016a4.505 4.505 0 0 1-4.5-4.5c0-2.482 2.019-4.5 4.5-4.5s4.5 2.018 4.5 4.5c0 2.481-2.019 4.5-4.5 4.5zm0-7.125A2.628 2.628 0 0 0 9.328 8.39a2.628 2.628 0 0 0 2.625 2.625 2.628 2.628 0 0 0 2.625-2.625 2.628 2.628 0 0 0-2.625-2.625zm5.94 12.584c.4-.328.46-.919.131-1.32a6.923 6.923 0 0 0-5.374-2.546h-1.112c-2.09 0-4.049.929-5.374 2.547a.938.938 0 0 0 1.45 1.188 5.054 5.054 0 0 1 3.924-1.86h1.112c1.525 0 2.955.678 3.923 1.86a.936.936 0 0 0 1.32.131z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SvgIcon>
);
