import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PrescriberIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="27" height="28" viewBox="0 0 27 28">
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <g>
            <path
              d="M7.782 9.498h-1.27v13.184h13.182V9.498H7.782zm14.612 0v13.184c.635 0 1.27-.591 1.27-1.319V10.817c0-.729-.635-1.32-1.27-1.32zm-18.423 0v13.184c-.795 0-1.43-.591-1.43-1.319V10.817c0-.729.635-1.32 1.43-1.32zM18.424 6.86h3.97c2.224 0 3.97 1.771 3.97 3.956v10.546c0 2.184-1.746 3.955-3.97 3.955H3.971C1.747 25.318 0 23.548 0 21.363V10.817c0-2.185 1.747-3.956 3.97-3.956h3.812V5.543c0-2.184 1.906-3.955 3.97-3.955h2.7c2.224 0 3.972 1.771 3.972 3.955V6.86zm-2.7 0h-5.241V5.543c0-.727.635-1.317 1.27-1.318h2.7c.794.001 1.27.59 1.27 1.318V6.86zm-7.148 9.23c0-.73.477-1.319 1.271-1.319h1.906v-1.977c0-.73.635-1.318 1.43-1.318.635 0 1.27.589 1.27 1.318v1.977h1.906c.794 0 1.43.59 1.43 1.318 0 .728-.636 1.319-1.43 1.319h-1.906v1.977c0 .727-.635 1.318-1.27 1.318-.795 0-1.43-.59-1.43-1.318v-1.977H9.847c-.794 0-1.27-.591-1.27-1.319z"
              transform="translate(-633 -805) translate(633 805.5)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
