import { useEffect, useState } from 'react';
import { DELETE_MAIL_ROUTE } from 'src/components/RequestDeleteEmail/consts';

export const useRouterBranding = () => {
  const [routeWithBranding, setRouteWithBranding] = useState(false);

  const url = window.location.href;

  useEffect(() => {
    if (url.includes(DELETE_MAIL_ROUTE)) {
      setRouteWithBranding(false);
    } else {
      setRouteWithBranding(true);
    }
  }, [url]);

  return {
    routeWithBranding,
  };
};
