import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BannerAlertIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="25" height="23" viewBox="0 0 25 23">
      <g fill="#F9F9F9" fillRule="nonzero">
        <path d="M21.436 22.993H3.558a3.555 3.555 0 0 1-3.075-1.775 3.588 3.588 0 0 1-.02-3.562l8.94-15.842A3.518 3.518 0 0 1 12.496 0a3.518 3.518 0 0 1 3.095 1.814l8.942 15.843a3.588 3.588 0 0 1-.017 3.566A3.555 3.555 0 0 1 21.44 23l-.003-.007zm-8.94-21.05a1.605 1.605 0 0 0-1.412.829L2.149 18.614a1.639 1.639 0 0 0 .009 1.624c.29.501.823.81 1.4.812h17.878a1.623 1.623 0 0 0 1.4-.812c.29-.502.294-1.12.009-1.625L13.907 2.77a1.606 1.606 0 0 0-1.41-.828z" />
        <path d="M12.497 13.628a.97.97 0 0 1-.967-.972v-4.84a.97.97 0 0 1 .967-.972.97.97 0 0 1 .967.971v4.841a.97.97 0 0 1-.967.972zM12.497 17.894a.97.97 0 0 1-.967-.972v-.577a.97.97 0 0 1 .967-.971.97.97 0 0 1 .967.971v.577a.97.97 0 0 1-.967.972z" />
      </g>
    </SvgIcon>
  );
};
