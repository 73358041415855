import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import styled from '@emotion/styled';
import { ClientBranding } from 'src/types/branding';
import { useBranding } from 'src/providers/ClientBrandingProvider';
import { Logo } from 'src/components/Logo';
import { Link, Body2 } from 'src/components/Typography';
import * as R from 'ramda';
import { isNonNullable } from 'src/misc/logical';
import { useLocation } from 'react-router-dom';
import { LandingPageContainer } from '../LandingPage/Container';
import { DialogMobileApps } from './DialogMobileApps';
import { ContentPageContainer } from './Container';

const pickedBrandingProps = [
  'clientCitystatezip',
  'clientStreetAddress',
  'copyrightText',
  'headerfooterLinkTexts',
  'headerfooterLinkUrls',
] as const;

type FooterProps = Pick<ClientBranding, typeof pickedBrandingProps[number]>;
/**
 * @component Footer - renders the Member Portal footer used across the site
 *
 * @props FooterProps
 *
 * @example
 * ```
 * <Footer
 *  clientCitystatezip={'Foxborough, MA 02035'}
 *  clientStreetAddress={'1 Patriot Pl'}
 *  copyrightText={'© 2022. All Rights Reserved'}
 *  headerfooterLinkTexts={['NE Patriots', 'NFL']}
 *  headerfooterLinkUrls={['https://www.patriots.com', 'https://www.nfl.com']}
 * />
 * ```
 */
export const Footer: React.FC<FooterProps> = ({
  clientCitystatezip,
  clientStreetAddress,
  copyrightText,
  headerfooterLinkTexts,
  headerfooterLinkUrls,
}) => {
  const links = (headerfooterLinkTexts || []).reduce((result, text, index) => {
    const url = headerfooterLinkUrls?.[index];
    if (isNonNullable(url)) {
      result.push({
        text,
        url,
      });
    }
    return result;
  }, [] as { url: string; text: string }[]);
  const location = useLocation();

  const isLandingPage = location.pathname === '/login';
  const ContainerComponent = isLandingPage
    ? LandingPageContainer
    : ContentPageContainer;
  const [showDialogMobileApps, setShowDialogMobileApps] = useState(false);

  return (
    <FooterLayout>
      <ContainerComponent>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container columnSpacing={3} alignItems="center" height="100%">
              <Grid item xs={12} md={6} pb={{ xs: 2.25, md: 0 }}>
                <FooterLogo />
              </Grid>
              <Grid item xs={12} md={6} pb={{ xs: 2.25, md: 0 }}>
                <Box component={Body2} lineHeight={1.4}>
                  {clientStreetAddress}
                </Box>
                <Box component={Body2} lineHeight={1.4}>
                  {clientCitystatezip}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <LinksList>
                {links.map((l, i) => (
                  <li key={`footerLink${i}`}>
                    <Link href={l.url} target="_self">
                      {l.text}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to={'/support'}>Support</Link>
                </li>
              </LinksList>
              <LinksList>
                <li>
                  <Link to={'/privacy-policy'}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={'/terms-and-conditions'}>Terms of Service</Link>
                </li>
                <li>
                  <Link to={'/hipaa-policy'}>HIPAA</Link>
                </li>
                <li>
                  <Link to={'/faqs'}>FAQs</Link>
                </li>
              </LinksList>
              <LinksList>
                <li>
                  <Link onClick={() => setShowDialogMobileApps(true)}>
                    Get the Mobile App
                  </Link>
                  <DialogMobileApps
                    open={showDialogMobileApps}
                    onClose={() => setShowDialogMobileApps(false)}
                  />
                </li>
              </LinksList>
              {copyrightText && (
                <Box
                  textAlign={{ xs: 'center', md: 'right' }}
                  pt={{ xs: '20px', md: '6px' }}
                >
                  {copyrightText}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </ContainerComponent>
    </FooterLayout>
  );
};

const LinksList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    padding: 8px 0;
    > a,
    > button {
      padding: 2px 14px;
      display: block;
    }
  }

  margin-left: -14px;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: -14px;
    padding: 6px 0;
    &:first-of-type {
      padding-top: 0;
    }

    li {
      padding: 0;
    }
  }
`;

const FooterLayout = styled.footer`
  padding: 60px 0;
  background-color: var(--white);
`;

const FooterLogo = styled(Logo)`
  max-width: 140px;
  max-height: 50px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 200px;
  }
`;

/**
 * @component BrandedFooter - renders the branded Member Portal footer to be
 * used across the site by pulling the branding data from the react context
 *
 * @props FooterProps
 *
 * @example
 * ```
 * <Footer />
 * ```
 */
const BrandedFooter: React.FC<{}> = () => {
  const branding = useBranding() || {};
  const picked = R.pick(pickedBrandingProps, branding) as FooterProps;
  return <Footer {...picked} />;
};

export default BrandedFooter;
