import { GetEnabledModulesResponse } from 'src/api/enabledModules';

export const enabledModuleQuerySettings = (cardId: number) => {
  const querySettings = {
    variables: {
      cardId: cardId as number,
    },
    options: {
      enabled: typeof cardId === 'number',
      select: (data: GetEnabledModulesResponse) => data.Result || [],
    },
  };
  return querySettings;
};
