import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SuccessCheckmarkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="104" height="104" viewBox="0 0 52 52">
      <g id="V1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="05"
          transform="translate(-564.000000, -449.000000)"
          stroke="#6BB536"
          strokeWidth="2"
        >
          <g id="Group-2" transform="translate(365.000000, 379.000000)">
            <g
              id="Success_arrow_icn"
              transform="translate(200.000000, 71.000000)"
            >
              <circle id="Oval" cx="25" cy="25" r="25"></circle>
              <polyline
                id="Path-2"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="12.9166667 25.1366565 20.5061197 34.1666667 37.0833333 15.8333333"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
