import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const BriefcaseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="22" height="20" viewBox="0 0 22 20" {...props}>
    <title>icons/briefcase</title>
    <g
      transform="translate(1 1)"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect y="4" width="20" height="14" rx="1.105" />
      <path d="M14 18V2h0a2 2 0 0 0-2-2H8h0a2 2 0 0 0-2 2s0 0 0 0v16" />
    </g>
  </SvgIcon>
);
