import MaterialDialog from '@mui/material/Dialog';
import type { DialogProps as MaterialDialogProps } from '@mui/material/Dialog';
import styled from '@emotion/styled/macro';
import { IconButton } from './Button';
import { CrossIcon } from './Icons';
import * as CSS from 'csstype';
import { css } from '@emotion/react';
import { getShouldForwardProp } from 'src/misc/styles';

export type DialogProps = MaterialDialogProps & {
  showCloseIcon?: boolean;
  maxWidthSize?: CSS.Property.MaxWidth;
};

/**
 * Component to display a Dialog Modal.
 * Underneath it uses Dialog Component from material ui library.
 * More information: https://mui.com/components/dialogs
 * @param showCloseIcon - it shows "close icon", not required
 * @param maxWidthSize - it creates max width for the modal container
 * @example
 * ```
  <Dialog
    maxWidthSize="374px"
    open={isOpen}
    onClose={closeDialog}
    showCloseIcon
  >
    <>content...</>
  </Dialog>
 * ```
 */
export const Dialog: React.FC<DialogProps> = ({
  showCloseIcon,
  children,
  maxWidthSize,
  ...props
}) => (
  <StyledDialog maxWidthSize={maxWidthSize} {...props}>
    {showCloseIcon && (
      <CloseIconWrapper>
        <CloseIconButton
          onClick={(event) =>
            props?.onClose && props.onClose(event, 'backdropClick')
          }
          title="close-dialog"
        >
          <CrossIcon sx={{ width: 34, height: 34 }} />
        </CloseIconButton>
      </CloseIconWrapper>
    )}
    {children}
  </StyledDialog>
);

const CloseIconWrapper = styled.div`
  padding-top: 21px;
`;

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 11px;
  top: 11px;
`;

const StyledDialog = styled(MaterialDialog, {
  shouldForwardProp: getShouldForwardProp({ omitProps: ['maxWidthSize'] }),
})<{
  maxWidthSize?: CSS.Property.MaxWidth;
}>`
  .MuiBackdrop-root {
    backdrop-filter: blur(5px);
    background-color: var(--modal-screen);
  }

  .MuiPaper-root {
    padding: 34px 26px 34px 33px;
  }

  ${({ maxWidthSize }) =>
    maxWidthSize &&
    css`
      .MuiPaper-root {
        max-width: ${maxWidthSize};
      }
    `}
`;
