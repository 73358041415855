import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ArrowDownIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="12px" height="8px" viewBox="0 0 12 8">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="dropdown-arrow"
          transform="translate(0.000000, -4.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g
            id="Dropdown-Arrow"
            transform="translate(6.000000, 6.000000) rotate(-45.000000) translate(-6.000000, -6.000000) translate(2.000000, 2.000000)"
          >
            <path
              d="M2,6 L7.49956,6 C7.77595,6 8,6.214035 8,6.504684 L8,7.495316 C8,7.774045 7.7692,8 7.49956,8 L1.49532,8 L0.50468,8 C0.22595,8 0,7.769203 0,7.499564 L0,0.500436 C0,0.224053 0.21404,0 0.50468,0 L1.49532,0 C1.77405,0 2,0.230797 2,0.500436 L2,6 Z"
              id="path-1"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
