import React, { PropsWithChildren, useContext } from 'react';
import { UseQueryResult } from 'react-query';
import {
  GetMemberAccountSettingInformationResponse,
  useGetMemberAccountSettingInformationQuery,
} from 'src/api/memberAccountSetting';
import { useAuth } from 'src/components/Auth';
import { LoaderMessage } from 'src/components/Loader';
import { ApiError } from 'src/types/api';

type MemberInformationsState = UseQueryResult<
  GetMemberAccountSettingInformationResponse,
  ApiError<GetMemberAccountSettingInformationResponse>
>;

const MemberInformationsStateContext =
  React.createContext<MemberInformationsState | null>(null);

export const MemberInformationsProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();

  const result = useGetMemberAccountSettingInformationQuery({
    options: {
      enabled: isLoggedIn,
      staleTime: 5 * 60 * 1000, //5 minutes
      retry: false,
    },
  });

  if (result.isLoading) {
    return <LoaderMessage />;
  }

  return (
    <MemberInformationsStateContext.Provider value={result}>
      {children}
    </MemberInformationsStateContext.Provider>
  );
};

export const useMemberInformations = (): MemberInformationsState => {
  const memberInformations = useContext(MemberInformationsStateContext);

  if (memberInformations === null) {
    throw new Error(
      'useMemberInformations must be used within a MemberInformationsProvider',
    );
  }

  return memberInformations;
};
