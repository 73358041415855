import styled from '@emotion/styled';
import React from 'react';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import { analyticsClickContentEvent } from 'src/services/analytics';

const LinkBase = styled.a`
  font-family: var(--default-font);
  font-size: var(--font-16);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--secondary-color);
  text-decoration: none;
  &:hover {
    color: var(--secondary-color-hover);
    text-decoration: none;
  }
`;

/**
 * @component InternalLink - renders either a styled Link from ReactRouter
 * (for internal navigation)
 *
 * @props ReactRouterLinkProps @alias for LinkProps
 *
 * @example
 * ```
 * <InternalLink to={'/example-route'}>Example Page</InternalLink>
 * ```
 */
export const InternalLink = LinkBase.withComponent(ReactRouterLink);

/**
 * @component ExternalLink - renders  a styled anchor tag (for external navigation)
 *
 * @props <React.AnchorHTMLAttributes<HTMLAnchorElement>
 *
 * @example
 * ```
 * <ExternalLink href={'https://www.example.dev'}>Example Third Party Site</ExternalLink>
 * ```
 */
export const ExternalLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ rel = 'noopener noreferrer', target = '_blank', ...rest }) => {
  return <LinkBase rel={rel} target={target} {...rest} />;
};

/**
 * @component ButtonLink - renders a button styled to visually appear as a link
 * for non-navigation click events. This is done for accessibility.
 *
 *  * * @example
 * ```
 * <ButtonLink onClick={() => { toggleSomething(true); }}>Toggle Something</ButtonLink>
 * ```
 */
export const ButtonLink = styled(LinkBase.withComponent('button'))`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
`;

export type LinkProps = React.HTMLAttributes<HTMLElement> &
  Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> &
  Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>> &
  Partial<ReactRouterLinkProps> & {
    trackingId?: string;
  };

/**
 * @component Link - renders either a styled Link from ReactRouter
 * (for internal navigation), a styled anchor tag (for external navigation),
 * or a button styled to visually appear as a link (for non-navigation click
 * events) depending on which props it is passed/
 *
 * @props LinkProps
 *
 * @example
 * as React Router Link
 * ```
 * <Link to={'/example-route'}>Example Page</Link>
 * ```
 *
 * * @example
 * as external Link
 * ```
 * <Link href={'https://www.example.dev'}>Example Third Party Site</Link>
 * ```
 *
 *  * * @example
 * as button link
 * ```
 * <Link onClick={() => { toggleSomething(true); }}>Toggle Something</Link>
 * ```
 */
export const Link: React.FC<LinkProps> = (props) => {
  const { to = '', href, trackingId, ...rest } = props;
  if (trackingId) {
    rest.onClick = (...args) => {
      analyticsClickContentEvent(trackingId);
      if (props.onClick) {
        props.onClick(...args);
      }
    };
  }
  if ('to' in props) {
    return <InternalLink to={to} {...rest} />;
  } else if ('href' in props && href !== '#') {
    return (
      <ExternalLink href={href} {...rest}>
        {rest.children}
      </ExternalLink>
    );
  } else if ('onClick' in props) {
    // Note: this does not preclude you from adding an onClick handler to a
    // different type of link.
    return <ButtonLink {...rest} />;
  } else {
    throw new Error("Please include a 'to', 'href', or 'onClick' prop");
  }
};

/**
 * TBD as to how often these styles (present in the stylegueide) are actually
 * used in the portal
 */
export const Link2 = styled(Link)`
  font-weight: 600;
`;

export const Link3 = styled(Link)`
  font-size: var(--font-14);
  font-weight: 600;
`;

export const Link4 = styled(Link)`
  font-size: var(--font-14);
`;

function extractTextFromChildren(children: React.ReactNode): string {
  if (Array.isArray(children)) {
    return children.map((child) => extractTextFromChildren(child)).join(' ');
  } else if (React.isValidElement(children)) {
    return extractTextFromChildren(children.props.children);
  }
  return children?.toString() || '';
}

/**
 * @component TrackingLink - renders a link with analytics tracking by default
 *
 * @props LinkProps
 *
 * @example
 * ```
 * <TrackingLink to={'/example-route'}>Example Page</InternalLink>
 * ```
 */
export const TrackingLink = (props: LinkProps) => (
  <Link
    trackingId={props.trackingId || extractTextFromChildren(props.children)}
    {...props}
  />
);
