import { Navigate, Outlet } from 'react-router-dom';
import { AutoLogout } from '../AutoLogout';
import { useAuth } from '../Auth';

export const SecureRoute = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <AutoLogout />
      <Outlet />
    </>
  );
};
