import { useQuery } from 'react-query';
import {
  getClientBrandingId,
  getContentfulClient,
} from '../services/fetchClientBranding';
import { ClientBranding } from '../types/branding';
import {
  EnvironmentSettings,
  RuntimeEnvironmentSettings,
} from '../types/environmentSettings';
import { useEnvironmentSettings } from '../providers/EnvironmentSettingsProvider';
import { getRuntimeSettings } from 'src/providers/GetSettings';

export type ClientBrandingResponse = {
  fields: ClientBranding;
} | null;
// Fetch base client assets & branding
export const getClientBrandingFn = async (
  settings: EnvironmentSettings,
  RuntimeEnvironmentSettings: RuntimeEnvironmentSettings | null,
): Promise<ClientBrandingResponse> => {
  const clientBrandingId = getClientBrandingId();
  const client = getContentfulClient(settings, RuntimeEnvironmentSettings);
  if (client) {
    try {
      const entry = await client.getEntry(clientBrandingId);
      return entry;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

export const useClientBrandingQuery = () => {
  const runtimeSettings = getRuntimeSettings();
  const settings = useEnvironmentSettings();

  return useQuery({
    queryKey: ['getClientBranding'],
    queryFn: () => getClientBrandingFn(settings, runtimeSettings),
  });
};
