import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

/**
 * Component to display "loader" as a message.
 */
export const LoaderMessage: React.FC = () => (
  <Wrapper title="loading-content">
    <SVG
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <Circle cx="25" cy="21" r="6"></Circle>
      <Circle cx="50" cy="21" r="6"></Circle>
      <Circle cx="75" cy="21" r="6"></Circle>
      <Circle cx="100" cy="21" r="6"></Circle>
    </SVG>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SVG = styled.svg`
  width: 128px;
  height: 42px;
  background: var(--white);
  border-radius: 21px;
  box-shadow: 0 9px 18px 0 rgba(0, 64, 128, 0.04),
    2px 2px 3px 0 rgba(167, 185, 191, 0.14);
  position: fixed;
  top: calc(50% - 21px);
  left: calc(50% - 64px);
  z-index: 20;
`;
const loadingScaler = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

const Circle = styled.circle`
  fill: var(--black-100);
  animation: ${loadingScaler} 1s infinite ease-in-out;

  &:nth-of-type(1) {
    opacity: 0.7;
    animation-delay: -0.375s;
    transform-origin: 25px 21px;
  }

  &:nth-of-type(2) {
    opacity: 0.5;
    animation-delay: -0.25s;
    transform-origin: 50px 21px;
  }

  &:nth-of-type(3) {
    opacity: 0.3;
    animation-delay: -0.125s;
    transform-origin: 75px 21px;
  }

  &:nth-of-type(4) {
    opacity: 0.1;
    animation-delay: 0s;
    transform-origin: 100px 21px;
  }
`;
