import { Dialog } from '../Dialog';
import type { DialogProps } from '../Dialog';
import Box from '@mui/material/Box';
import { Button } from '../Button';
import { H3, Body2 } from '../Typography';

export type DialogAutoLogoutProps = DialogProps & {
  onLogout?: React.MouseEventHandler<HTMLButtonElement>;
  onStayLoggedIn?: React.MouseEventHandler<HTMLButtonElement>;
};

export const DialogAutoLogout: React.FC<DialogAutoLogoutProps> = ({
  onLogout,
  onStayLoggedIn,
  ...props
}) => {
  return (
    <Dialog {...props} maxWidthSize="413px">
      <Box component={H3} mb={2.5}>
        Inactive Warning
      </Box>

      <Box component={Body2} mb={2} lineHeight="1.38">
        You have been inactive for over 10 minutes. Please choose to Stay Logged
        In or Logout. Otherwise you will be automatically logged out in 5
        minutes.
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button color="gray" onClick={onLogout} variant="outlined">
          Logout
        </Button>
        <Box component={Button} flex={1} onClick={onStayLoggedIn} ml={2}>
          Stay Logged In
        </Box>
      </Box>
    </Dialog>
  );
};
