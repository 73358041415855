type DataObject = { [key: string]: any };

export function obfuscateSensitiveData(data: any): any {
  if (typeof data === 'object') {
    if (data && Object.keys(data).length > 0) {
      const newData: DataObject = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (typeof value === 'string') {
            if (key.toLowerCase().includes('email') && value.includes('@')) {
              const parts = value.split('@');
              const username = parts[0];
              const domain = parts[1];
              const obfuscatedUsername =
                username.length >= 2
                  ? `${username[0]}${'*'.repeat(5)}${username.slice(-1)}`
                  : `*****`;
              newData[key] = `${obfuscatedUsername}@${domain}`;
            } else if (
              key.toLowerCase().includes('phone') ||
              key.toLowerCase().includes('number')
            ) {
              newData[key] = value.replace(
                /^\d+(?=.{4})/,
                '*'.repeat(Math.max(value.length - 4, 0)),
              );
            } else if (key.toLowerCase().includes('password')) {
              newData[key] = '*'.repeat(value.length);
            } else if (
              key.toLowerCase().includes('firstname') ||
              key.toLowerCase().includes('lastname')
            ) {
              newData[key] =
                value.length >= 2
                  ? `${value[0]}${'*'.repeat(5)}${value.slice(-1)}`
                  : `*****`;
            } else {
              newData[key] = value;
            }
          } else {
            newData[key] = value;
          }
        }
      }
      return newData;
    }
  } else if (typeof data === 'string') {
    return data.length > 2
      ? `${data[0]}${'*'.repeat(Math.max(data.length - 2, 0))}${data.slice(-1)}`
      : data;
  }
  return '';
}
