import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const UnarchiveIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M24 11L24 24 6 24 6 11M4 6H26V11H4z"
        />
        <g
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path
            d="M7.53 0.509L4.03 4.009 0.53 0.509"
            transform="translate(11 15) matrix(1 0 0 -1 0 4.519)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
