import type { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { useRequest } from './useRequest';
import type { UseRequestConfig } from './useRequest';
import { getRuntimeSettings } from 'src/providers/GetSettings';

/**
 * It returns an async function that allow making a request to Identity Service API
 * @param config - AxiosRequestConfig
 * @returns async function for making request to Identity Service API with axios
 */
export const useRequestIdentityService = <TQueryFnData = unknown>(
  config?: UseRequestConfig,
) => {
  const settings = getRuntimeSettings();

  const identityServiceAPIConfig: AxiosRequestConfig = {
    paramsSerializer: qs.stringify,
    baseURL: settings?.REACT_APP_IDENTITY_OAUTH,
  };

  return useRequest<TQueryFnData>({
    ...identityServiceAPIConfig,
    ...config,
  });
};
