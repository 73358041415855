import type { ApiError, MemberAPIResponse } from 'src/types/api';
import { createUseQueryHook } from 'src/services/createQueryHook';
import type { UseQueryFn } from 'src/services/createQueryHook';
import { apiUrls, apiIdentityServiceUrls } from 'src/services/apiUrls';
import { useRequestMemberAPI } from 'src/hooks/useRequestMemberAPI';
import { useRequestIdentityService } from 'src/hooks/useRequestIdentityService';
import { MemberInformation } from 'src/types/member';

export type GetMemberAccountSettingInformationResponse =
  MemberAPIResponse<MemberInformation>;

const useQueryFnGet: UseQueryFn<
  GetMemberAccountSettingInformationResponse
> = () => {
  const request =
    useRequestMemberAPI<GetMemberAccountSettingInformationResponse>({
      method: 'GET',
      url: apiUrls.memberAccountSettingInformation,
      withAuthorization: true,
    });
  return () => request();
};

export const useGetMemberAccountSettingInformationQuery = createUseQueryHook<
  GetMemberAccountSettingInformationResponse,
  undefined,
  ApiError<GetMemberAccountSettingInformationResponse>
>({
  id: 'getMemberAccountSettingInformation',
  useQueryFn: useQueryFnGet,
});

export type GetUserInfoResponse = {
  name: string;
  phone_number: string;
  phone_number_verified: boolean;
  preferred_username: string;
  sub: string;
};

const useQueryFnGetUserInfo: UseQueryFn<GetUserInfoResponse> = () => {
  const request = useRequestIdentityService<GetUserInfoResponse>({
    withAuthorization: true,
    method: 'GET',
    url: apiIdentityServiceUrls.userInfo,
  });

  return () => request();
};

export const useGetUserInfoQuery = createUseQueryHook({
  id: 'getUserInfo',
  useQueryFn: useQueryFnGetUserInfo,
});
