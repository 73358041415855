import React, { createContext, useContext, useEffect, useState } from 'react';
import { MemberBenefit, Profile } from 'src/types/member';
import { useGetMemberCardAndGroupQuery } from 'src/api/dashboard';
import { LoaderMessage } from 'src/components/Loader';
import { compareDates } from 'src/misc/date';
import { useAuth } from './Auth';

const sortByEndDateDescending = (planA: MemberBenefit, planB: MemberBenefit) =>
  compareDates(
    planB?.MemberCard?.EndDate || '',
    planA?.MemberCard?.EndDate || '',
  );

const sortByStartDateAscending = (planA: MemberBenefit, planB: MemberBenefit) =>
  compareDates(
    planA?.MemberCard?.StartDate || '',
    planB?.MemberCard?.StartDate || '',
  );

// MemberBenefitContext: all cards, all members

export const MemberBenefitContext = createContext<MemberBenefit[] | undefined>(
  undefined,
);
export const useMemberBenefit = () => useContext(MemberBenefitContext);

export const ActiveInactivePlansContext = createContext<{
  activePlans: MemberBenefit[];
  inactivePlans: MemberBenefit[];
}>({ activePlans: [], inactivePlans: [] });
export const useActiveInactivePlans = () =>
  useContext(ActiveInactivePlansContext);

// SelectedCardContext: selected card, all members

export type SelectedCardContextType = {
  selectedCard: MemberBenefit | undefined;
  setSelectedCard: React.Dispatch<
    React.SetStateAction<MemberBenefit | undefined>
  >;
};
export const SelectedCardContext = createContext<SelectedCardContextType>({
  selectedCard: undefined,
  setSelectedCard: () => {},
});
export const useSelectedCard = () => useContext(SelectedCardContext);

// SelectedMemberContext: selected card, selected member

export type SelectedMemberContextType = {
  selectedMember: Profile | undefined;
  setSelectedMember: React.Dispatch<React.SetStateAction<Profile | undefined>>;
};
export const SelectedMemberContext = createContext<SelectedMemberContextType>({
  selectedMember: undefined,
  setSelectedMember: () => {},
});
export const useSelectedMember = () => useContext(SelectedMemberContext);

export const BenefitAndMemberDataProvider: React.FC = ({ children }) => {
  const [memberBenefit, setMemberBenefit] = useState<
    MemberBenefit[] | undefined
  >();

  // Upon initialization, check if a member is authenticated - if so, fetch their member benefit information
  const { isLoggedIn } = useAuth();
  const { data, isLoading } = useGetMemberCardAndGroupQuery({
    options: {
      enabled: isLoggedIn,
    },
  });

  const [selectedCard, setSelectedCard] = useState<MemberBenefit | undefined>();
  const selectedCardContext = { selectedCard, setSelectedCard };

  const [selectedMember, setSelectedMember] = useState<Profile | undefined>();
  const selectedMemberContext = { selectedMember, setSelectedMember };

  const [{ activePlans, inactivePlans }, setActiveAndInactivePlans] = useState<{
    activePlans: MemberBenefit[];
    inactivePlans: MemberBenefit[];
  }>({ activePlans: [], inactivePlans: [] });
  const activeInactivePlansContext = {
    activePlans,
    inactivePlans,
  }; /* Not exporting setActiveAndInactivePlans */

  // Initialize with the first card returned from the API
  useEffect(() => {
    const activePlans: MemberBenefit[] = [];
    const inactivePlans: MemberBenefit[] = [];
    setMemberBenefit(data?.Result);
    data?.Result.forEach((benefitCard) => {
      const { MemberCard, IsTermed } = benefitCard;
      MemberCard?.IsActive && !IsTermed
        ? activePlans.push(benefitCard)
        : inactivePlans.push(benefitCard);
    });
    setActiveAndInactivePlans({
      activePlans: activePlans.sort(sortByStartDateAscending),
      inactivePlans: inactivePlans.sort(sortByEndDateDescending),
    });
    const card = data?.Result[0];
    setSelectedCard(card);
    const member = data?.Result[0]?.Members
      ? data?.Result[0]?.Members[0]
      : undefined;
    setSelectedMember(member);

    const memberId = member?.MemberId?.toString() ?? null;
    newrelic.setUserId(memberId);
    newrelic.setCustomAttribute('selectedMemberId', memberId ?? '');
    newrelic.setCustomAttribute('selectedGroup', card?.GroupName ?? '');
  }, [data]);

  useEffect(() => {
    const card =
      selectedMember &&
      memberBenefit?.find((benefit) =>
        benefit?.Members?.some(
          (member) =>
            member?.MemberCard?.MemberCardId ===
            selectedMember?.MemberCard?.MemberCardId,
        ),
      );
    if (card) {
      setSelectedCard(card);
    }
  }, [selectedMember, memberBenefit]);

  if (isLoading) {
    return <LoaderMessage />;
  }

  return (
    <MemberBenefitContext.Provider value={memberBenefit}>
      <ActiveInactivePlansContext.Provider value={activeInactivePlansContext}>
        <SelectedCardContext.Provider value={selectedCardContext}>
          <SelectedMemberContext.Provider value={selectedMemberContext}>
            {children}
          </SelectedMemberContext.Provider>
        </SelectedCardContext.Provider>
      </ActiveInactivePlansContext.Provider>
    </MemberBenefitContext.Provider>
  );
};
