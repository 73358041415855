import { useQuery, useInfiniteQuery } from 'react-query';
import type { UseQueryOptions, UseInfiniteQueryOptions } from 'react-query';
import { isNonNullable } from 'src/misc/logical';
import type { ApiError } from 'src/types/api';

export type QueryFnParams<TVariables = undefined> = {
  variables?: TVariables;
  pageParam?: number;
};

export type QueryFn<TQueryFnData = unknown, TVariables = undefined> = (
  params: QueryFnParams<TVariables>,
) => Promise<TQueryFnData>;

export type UseQueryFn<
  TQueryFnData = unknown,
  TVariables = undefined,
> = () => QueryFn<TQueryFnData, TVariables>;

export type CreateUseQueryHookConfig<
  TQueryFnData = unknown,
  TVariables = undefined,
> = {
  id: string;
  useQueryFn: UseQueryFn<TQueryFnData, TVariables>;
};

type UseQueryHookConfig<
  TQueryFnData = unknown,
  TVariables = undefined,
  TData = TQueryFnData,
  TError = ApiError,
> = {
  variables?: TVariables;
  options?: UseQueryOptions<TQueryFnData, TError, TData>;
};

type UseInfiniteQueryHookConfig<
  TQueryFnData = unknown,
  TVariables = undefined,
  TData = TQueryFnData,
  TError = ApiError,
> = {
  variables?: TVariables;
  options?: UseInfiniteQueryOptions<TQueryFnData, TError, TData>;
};

/**
 * It creates useQuery hook in a convenient way
 * @param config - configuration
 * @example
 * ```
  export const useGetMemberBenefitDataQuery = createUseQueryHook({
    id: 'getMemberBenefitData',
    useQueryFn: useQueryFnGet,
  });

  const useQueryFnGet: UseQueryFn<Response, Variables> = () => {
    const request = useRequestMemberAPI<Response>({
      method: 'GET',
      url: apiUrls.memberCardAndGroup,
      withAuthorization: true,
    });
    return ({ variables }) => request({ params: variables });
  };
 * ```
 */
export const createUseQueryHook = <
  TQueryFnData = unknown,
  TVariables = undefined,
  TError = ApiError,
>({
  id,
  useQueryFn,
}: CreateUseQueryHookConfig<TQueryFnData, TVariables>) => {
  const getKey = (variables?: TVariables) =>
    isNonNullable(variables) ? [id, variables] : [id];

  const useQueryHook = <TData = TQueryFnData>({
    variables,
    options,
  }: UseQueryHookConfig<TQueryFnData, TVariables, TData, TError> = {}) => {
    const queryFn = useQueryFn();

    return useQuery<TQueryFnData, TError, TData>(
      getKey(variables),
      () => queryFn({ variables }),
      options,
    );
  };

  useQueryHook.getKey = getKey;

  return useQueryHook;
};

export const createUseInfinityQueryHook = <
  TQueryFnData = unknown,
  TVariables = undefined,
  TError = ApiError,
>({
  id,
  useQueryFn,
}: CreateUseQueryHookConfig<TQueryFnData, TVariables>) => {
  const getKey = (variables?: TVariables) =>
    isNonNullable(variables) ? [id, variables] : [id];

  const useInfiniteQueryHook = <TData = TQueryFnData>({
    variables,
    options,
  }: UseInfiniteQueryHookConfig<
    TQueryFnData,
    TVariables,
    TData,
    TError
  > = {}) => {
    const queryFn = useQueryFn();

    return useInfiniteQuery<TQueryFnData, TError, TData>(
      getKey(variables),
      ({ pageParam = 1 }) => queryFn({ variables, pageParam }),
      options,
    );
  };

  useInfiniteQueryHook.getKey = getKey;

  return useInfiniteQueryHook;
};
