import { Global, css } from '@emotion/react';
import { useBrandingStyles } from './useBrandingStyles';
import { useGlobalStyles } from './useGlobalStyles';

export const GlobalStyles = () => {
  const brandingStyles = useBrandingStyles();
  const globalStyles = useGlobalStyles();

  return (
    <Global
      styles={css`
        ${brandingStyles}
        ${globalStyles}
      `}
    />
  );
};
