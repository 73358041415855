import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CrossSearchIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="9px"
      height="9px"
      viewBox="0 0 9 9"
      version="1.1"
    >
      <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01" transform="translate(-8, -8)">
          <polygon
            id="+"
            stroke="currentColor"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(12.500000, 12.500000) rotate(-315.000000) translate(-12.500000, -12.500000) "
            points="12.8797468 17 12.8797468 12.8987342 17 12.8987342 17 12.1202532 12.8797468 12.1202532 12.8797468 8 12.1012658 8 12.1012658 12.1202532 8 12.1202532 8 12.8987342 12.1012658 12.8987342 12.1012658 17"
          ></polygon>
        </g>
      </g>
    </SvgIcon>
  );
};
