import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowRightIcon } from './ArrowRightIcon';
import { getShouldForwardProp } from 'src/misc/styles';

export type DropdownArrowRightIconProps = {
  isOpen?: boolean;
};

/**
 * This styled component is based on arrow-right-icon(>) and
 * it transforms it by 90deg to arrow-down-icon(v). Often used for show/hide menu list
 * @prop isOpen - when false It'll display arrow-right-icon(>), when true It'll rotate it by 90deg
 */
export const DropdownArrowRightIcon = styled(ArrowRightIcon, {
  shouldForwardProp: getShouldForwardProp({
    omitProps: ['isOpen'],
  }),
})<DropdownArrowRightIconProps>`
  transition: ${({ theme }) => theme.transitions.create('transform')};
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(90deg);
    `};
`;
