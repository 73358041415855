import { useEnvironmentSettings } from './EnvironmentSettingsProvider';
import { SPLIT_API_DEFAULT_KEY } from '../misc/constants';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { LoaderMessage } from '../components/Loader';
import { ISplitFactoryChildProps } from '@splitsoftware/splitio-react/types/types';

type Props = {
  children:
    | ((props: ISplitFactoryChildProps) => JSX.Element | null)
    | JSX.Element
    | null;
};

export const LocalhostSplitioWrapperProvider = ({ children }: Props) => (
  <SplitFactory
    config={{
      core: {
        authorizationKey: 'localhost',
        key: 'user_id',
      },
    }}
  >
    {children}
  </SplitFactory>
);

const SplitioWrapperProvider = ({ children }: Props) => {
  const settings = useEnvironmentSettings();

  if (settings) {
    const serverSplitFactoryConfigs: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: settings.SplitIoApiKey ?? '',
        key: SPLIT_API_DEFAULT_KEY,
      },
    };

    return (
      <SplitFactory config={serverSplitFactoryConfigs}>{children}</SplitFactory>
    );
  } else {
    return <LoaderMessage />;
  }
};

export default SplitioWrapperProvider;
