import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PharmacyIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="23" height="23" viewBox="0 0 23 23">
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <g>
            <g>
              <path
                d="M4.291 8.593c-1.424 0-2.459-1.376-2.058-2.75l1.074-3.695H6.44v4.297c0 1.186-.957 2.148-2.148 2.148zm3.222.694c-.79.891-1.94 1.454-3.222 1.454v4.297h6.445V10.74c-1.281 0-2.433-.563-3.223-1.454zm-3.222 7.899v2.148h6.445v-2.148H4.29zm8.593 2.148h4.296v-8.593c-1.28 0-2.433-.563-3.222-1.454-.31.348-.673.645-1.074.88v9.167zm6.445-9.165c1.63-.948 2.549-2.937 1.98-4.928l-1.178-4.01C19.924.5 19.251 0 18.487 0H2.983C2.22 0 1.548.5 1.34 1.23L.176 5.242c-.583 1.99.336 3.98 1.967 4.928v9.165c0 1.187.97 2.148 2.148 2.148h12.89c1.19 0 2.148-.961 2.148-2.148V10.17zm-4.297-3.724V2.148h3.132l1.074 3.695c.401 1.374-.621 2.75-2.058 2.75-1.19 0-2.148-.962-2.148-2.148zM8.587 2.148h4.297v4.297c0 1.186-.958 2.148-2.148 2.148-1.19 0-2.149-.962-2.149-2.148V2.148z"
                transform="translate(-43 -810) translate(18.5 788.5) translate(24.7 22.5)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
