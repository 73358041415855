import { useBranding } from '../providers/ClientBrandingProvider';

type ImageHTMLProps = React.ImgHTMLAttributes<HTMLImageElement>;

export type LogoProps = Omit<ImageHTMLProps, 'src'>;

export const Logo: React.FC<LogoProps> = (props) => {
  const brandingData = useBranding();

  if (!brandingData?.logo?.fields?.file?.url) {
    return null;
  }

  return (
    <img
      src={brandingData.logo.fields.file.url}
      alt={brandingData.logo.fields.title || ''}
      {...props}
    />
  );
};

export default Logo;
