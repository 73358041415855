import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, GlobalStyles } from './theme';
import { PublicRouter, Router } from './Router';
import ClientBrandingProvider from './providers/ClientBrandingProvider';
import EnvironmentSettingsProvider from './providers/EnvironmentSettingsProvider';
import SplitioWrapperProvider from './providers/FeatureFlagProvider';
import { AuthProvider } from './components/Auth';
import { setupBroadcastChannel } from './services/broadcastChannel';
import { RegisterContextProvider } from './pages/Registration/RegisterContext';
import { useRouterBranding } from './hooks/useRouterBranding';

setupBroadcastChannel();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { routeWithBranding } = useRouterBranding();

  if (!routeWithBranding) {
    return (
      <ThemeProvider>
        <CssBaseline />
        <GlobalStyles />
        <PublicRouter />
      </ThemeProvider>
    );
  }
  window.dataLayer.push({
    event: 'pageview',
  });

  return (
    <QueryClientProvider client={queryClient}>
      <EnvironmentSettingsProvider>
        <SplitioWrapperProvider>
          <AuthProvider>
            <StyledEngineProvider injectFirst />
            <ClientBrandingProvider>
              <RegisterContextProvider>
                <ThemeProvider>
                  <CssBaseline />
                  <GlobalStyles />
                  <Router />
                </ThemeProvider>
              </RegisterContextProvider>
            </ClientBrandingProvider>
          </AuthProvider>
        </SplitioWrapperProvider>
      </EnvironmentSettingsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
