import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid, { type GridProps } from '@mui/material/Grid';
import { Dialog as _Dialog, type DialogProps } from '../../Dialog';
import { H2 } from '../../Typography';
import { AppQRCode } from './AppQRCode';

export type DialogMobileAppsProps = DialogProps;
const gridItemQRCodeProps: GridProps = {
  item: true,
  xs: 12,
  md: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: { xs: 'center', md: 'flex-start' },
};

export const DialogMobileApps: React.FC<DialogMobileAppsProps> = ({
  ...props
}) => {
  return (
    <Dialog {...props} maxWidthSize="592px" showCloseIcon>
      <Box
        component={H2}
        mb={3.5}
        fontWeight={600}
        textAlign={{ xs: 'center', md: 'left' }}
      >
        Scan With Your Phone Camera to Download the Mobile App
      </Box>
      <Grid container>
        <Grid {...gridItemQRCodeProps}>
          <AppQRCode
            title="Download for iOS"
            appLink="https://apps.apple.com/us/app/myrxplan/id1532903944"
          />
        </Grid>
        <Grid
          item
          xs={false}
          md
          display={{ xs: 'none', md: 'flex' }}
          justifyContent="center"
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid
          item
          xs={12}
          display={{ xs: 'block', md: 'none' }}
          textAlign="center"
        >
          <MobileDivider orientation="horizontal" flexItem />
        </Grid>
        <Grid {...gridItemQRCodeProps}>
          <AppQRCode
            title="Download for Android"
            appLink="https://play.google.com/store/apps/details?id=com.rxsense.rxagile.memberapp"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const Dialog = styled(_Dialog)`
  .MuiPaper-root {
    padding: 34px 50px;
  }
`;

const MobileDivider = styled(Divider)`
  max-width: 184px;
  margin: auto;
  margin-top: 28px;
  margin-bottom: 30px;
`;
