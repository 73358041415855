import { Toolbar, Box, Container } from '@mui/material';
import {
  HeaderLink,
  HeaderLinksWrapper,
  HeaderWrapper,
  LogoImg,
} from 'src/components/Header/Header.styled';
import { Button } from 'src/components/Button';
import { SideMenu } from './SideMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'src/components/Link';
import {
  useTopHeaderLinks,
  mapMemberBenefitToMenuBenefitCard,
} from 'src/components/Header/HeaderComputations';
import { useAuth } from 'src/components/Auth';
import { AccountMenu } from './AccountMenu';
import { useLocation } from 'react-router-dom';
import { LandingPageContainer } from 'src/components/LandingPage/Container';
import { titleCase } from 'src/misc/formatters';
import {
  useSelectedMember,
  useActiveInactivePlans,
} from 'src/components/MemberBenefitController';
import { HeaderActionLink } from 'src/components/Header/types';

const settingsActionLink: HeaderActionLink = {
  label: 'Account Settings',
  to: '/settings',
};

const supportActionLink: HeaderActionLink = {
  label: 'Support',
  to: '/support',
};

export const Header: React.FC = () => {
  const topLinksArr = useTopHeaderLinks();
  const location = useLocation();
  const isLandingPage = location.pathname === '/login';
  const shouldDisplayFullScreen = useMediaQuery('(min-width: 1296px)');
  const { login, user, logout, isLoggedIn } = useAuth();
  const userProfileName = user?.profile?.name;
  const { activePlans, inactivePlans } = useActiveInactivePlans();
  const activeMenuBenefitCards = activePlans.map(
    mapMemberBenefitToMenuBenefitCard,
  );
  const inactiveMenuBenefitCards = inactivePlans.map(
    mapMemberBenefitToMenuBenefitCard,
  );
  const { selectedMember } = useSelectedMember();
  const accountName = titleCase(selectedMember?.FirstName) || userProfileName;

  const logOutActionLink: HeaderActionLink = {
    label: 'Log Out',
    onClick: logout,
  };

  const logInActionLink: HeaderActionLink = {
    label: 'Log In',
    onClick: login,
  };

  const menuLinks = isLoggedIn
    ? [settingsActionLink, supportActionLink, logOutActionLink]
    : [supportActionLink, logInActionLink];

  return (
    <HeaderWrapper position="relative">
      <Toolbar
        component={isLandingPage ? LandingPageContainer : Container}
        maxWidth={!isLandingPage && 'lg'}
      >
        <Link to="/">
          <LogoImg />
        </Link>
        {shouldDisplayFullScreen && (
          <HeaderLinksWrapper>
            {topLinksArr.map((link, index) => {
              const { to, href, label } = link;
              const urlProp = to ? { to } : { href, target: '_self' };
              return (
                <HeaderLink {...urlProp} key={`link${label}${index}`}>
                  {link.label}
                </HeaderLink>
              );
            })}
          </HeaderLinksWrapper>
        )}
        {!isLoggedIn && (
          <Box
            display="flex"
            ml={'auto'}
            justifyContent="center"
            alignItems="center"
          >
            {shouldDisplayFullScreen && (
              <HeaderLink to={'/support'}>Support</HeaderLink>
            )}
            <Button
              onClick={login}
              variant="solid"
              size={{ xs: 'medium', lg: 'large' }}
            >
              Log In
            </Button>
          </Box>
        )}
        {shouldDisplayFullScreen ? (
          isLoggedIn && (
            <AccountMenu
              accountName={accountName}
              accountOpts={menuLinks}
              activePlans={activeMenuBenefitCards}
              inactivePlans={inactiveMenuBenefitCards}
            />
          )
        ) : (
          <SideMenu
            isLoggedIn={isLoggedIn}
            accountName={accountName}
            topLinks={topLinksArr}
            bottomLinks={menuLinks}
            activePlans={activeMenuBenefitCards}
            inactivePlans={inactiveMenuBenefitCards}
          />
        )}
      </Toolbar>
    </HeaderWrapper>
  );
};
