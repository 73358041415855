import { css, useTheme } from '@emotion/react';

export const useGlobalStyles = () => {
  const theme = useTheme();

  return css`
    html {
      font-size: 16px;
    }
    body {
      background-color: var(--neutral-color-1);
      color: var(--charcol-black);
      font-family: var(--default-font);
    }
    ${theme.breakpoints.down('sm')} {
      html {
        font-size: 14px;
      }
    }
    // Wrapper to stretch the Outlet component and position the
    // footer at the bottom of the viewport if viewport exceeds
    // the page's content height
    [id='root'] {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      min-height: 100vh;
    }
  `;
};
