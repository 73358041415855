import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PrintIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M4 7L4 0 16 0 16 7M4 16H2c-1.105 0-2-.895-2-2V9c0-1.105.895-2 2-2h16c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2h-2"
                transform="translate(-1118 -125) translate(1118 125) translate(5 5)"
              />
              <path
                d="M4 12H16V20H4z"
                transform="translate(-1118 -125) translate(1118 125) translate(5 5)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
