import isPropValid from '@emotion/is-prop-valid';

/**
 * utility for using shouldForwardProp option with `emotion` components
 * @example
 * styled('button', {
    shouldForwardProp: getShouldForwardProp({
      omitProps: ['color', 'variant', 'size'],
    }),
  })
 */
export const getShouldForwardProp =
  (
    config: {
      omitProps?: string[];
      forwardProps?: string[];
      useDefault?: boolean;
    } = {},
  ) =>
  (prop: PropertyKey) => {
    if (config.omitProps && config.omitProps.find((v) => v === prop)) {
      return false;
    }
    if (config.forwardProps && config.forwardProps.find((v) => v === prop)) {
      return true;
    }
    if (config.useDefault) {
      return isPropValid(prop as string); // fixing typescript error from package
      //       Argument of type 'PropertyKey' is not assignable to parameter of type 'string'.
      //   Type 'number' is not assignable to type 'string'.ts(2345)
      // (parameter) prop: PropertyKey
    }
    return true;
  };
