import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowDownIcon } from './ArrowDownIcon';
import { getShouldForwardProp } from 'src/misc/styles';

export type DropdownArrowDownIconProps = {
  isOpen?: boolean;
};

/**
 * This styled component is based on arrow-dropdown-icon(v) and
 * it transforms it by -180deg to arrow-up-icon(^). Often used for show/hide menu list
 * @prop isOpen - when false It'll display arrow-dropdown-icon(v), when true It'll rotate it by -180deg
 */
export const DropdownArrowDownIcon = styled(ArrowDownIcon, {
  shouldForwardProp: getShouldForwardProp({
    omitProps: ['isOpen'],
  }),
})<DropdownArrowDownIconProps>`
  transition: ${({ theme }) => theme.transitions.create('transform')};
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(-180deg);
    `};
`;
