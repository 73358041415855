import type { MemberAPIResponse } from 'src/types/api';
import type { EnabledModules } from 'src/types/enabledModules';
import { createUseQueryHook } from 'src/services/createQueryHook';
import type { UseQueryFn } from 'src/services/createQueryHook';
import { apiUrls } from 'src/services/apiUrls';
import { useRequestMemberAPI } from 'src/hooks/useRequestMemberAPI';

export type GetEnabledModulesResponse = MemberAPIResponse<EnabledModules>;
export type GetEnabledModulesParams = {
  cardId: number;
};
const useQueryFnGet: UseQueryFn<
  GetEnabledModulesResponse,
  GetEnabledModulesParams
> = () => {
  const request = useRequestMemberAPI<GetEnabledModulesResponse>({
    method: 'GET',
    url: apiUrls.enabledModules,
    withAuthorization: true,
  });
  return ({ variables }) => request({ params: variables });
};

export const useGetEnabledModulesQuery = createUseQueryHook({
  id: 'getEnabledModules',
  useQueryFn: useQueryFnGet,
});
