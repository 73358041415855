import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Menu, Close } from '@mui/icons-material';
import {
  IconButton,
  Drawer,
  Box,
  List,
  ListItem as MuiListItem,
  ListSubheader,
  Divider,
  Select as MuiSelect,
  MenuItem,
} from '@mui/material';
import { UserCircleIcon, SelectArrowIcon } from 'src/components/Icons';
import { Link, Title4 } from 'src/components/Typography';
import type {
  HeaderActionLink,
  MenuBenefitCard,
} from 'src/components/Header/types';
import { useSelectedMember } from 'src/components/MemberBenefitController';
import { Profile } from 'src/types/member';

const SideMenuLink = styled(Link)`
  font-size: var(--font-20);
  font-weight: 500;
  flex-grow: 1;
  color: var(--black-100);
  &:hover {
    color: var(--black-100);
  }
`;

const ProfileLink = styled(SideMenuLink)`
  font-size: var(--font-18);
  font-weight: normal;
`;

const AccountName = styled(Title4)`
  flex-grow: 1;
  padding-right: 7px;
  line-height: 30px;
  color: var(--black-100);
  word-break: break-word;
`;

const ListItem = styled(MuiListItem)`
  padding-left: 25px;
`;

const CardSelect = styled(MuiSelect)`
  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: solid 1px #ebebeb;
    }
  }
`;

interface SideMenuProps {
  accountName?: string;
  topLinks?: any[];
  bottomLinks?: HeaderActionLink[];
  isLoggedIn: boolean;
  activePlans: MenuBenefitCard[];
  inactivePlans: MenuBenefitCard[];
}

/**
 * This component is a sidemenu gets displayed for lower resolutions like tabled & mobile.
 * Based on topLinks, profileList and bottomLinks props It shows options to navigate
 * @prop accountName is username,
 * @prop topLinks is a list of links shown in Header with corresponding events
 * @prop bottomLinks are Support, Settings and Logout etc.
 * @prop activePlans - list of MenuBenefitCard objects representing a user's active
 *   associated benefit cards and the profiles (dependents) attached to each card
 * @prop iactivePlans - list of MenuBenefitCard objects representing a user's iactive
 *   associated benefit cards and the profiles (dependents) attached to each card
 * @returns SideMenu component with all required functioning
 */
export const SideMenu: React.FC<SideMenuProps> = ({
  accountName = '',
  topLinks = [],
  bottomLinks = [],
  isLoggedIn = false,
  activePlans,
  inactivePlans,
}) => {
  // In order to display the list of plans with Active/Inactive labels,
  // we want to keep track of where the active plans will stop in the
  // memberCardsAndProfilesList
  const activePlansCutoffIndex = activePlans.length;
  const memberCardsAndProfilesList = [...activePlans, ...inactivePlans];
  const { setSelectedMember } = useSelectedMember();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpenState] = useState(false);

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const profilesList =
    memberCardsAndProfilesList[selectedCardIndex]?.profiles || [];

  const handleOnProfileChangeClick = (profile: Profile) => {
    setMenuOpenState(false);
    if (setSelectedMember) {
      setSelectedMember(profile);
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setMenuOpenState(open);
    };

  const handleActionLinkClick = (actionLink: HeaderActionLink) => {
    setMenuOpenState(false);
    if (actionLink?.onClick) {
      actionLink.onClick();
    }
    if (actionLink?.to) {
      navigate(actionLink.to);
    }
  };

  return (
    <>
      <IconButton
        sx={isLoggedIn ? { marginLeft: 'auto' } : {}}
        edge="end"
        color="default"
        onClick={toggleDrawer(true)}
        aria-label="menu"
        size="large"
      >
        <Menu />
      </IconButton>
      <Drawer anchor={'right'} open={isMenuOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 366 }} role="presentation">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '40px 20px',
              fontSize: '2rem',
            }}
          >
            <UserCircleIcon
              sx={{
                width: 24,
                mr: '11px',
              }}
            />
            <AccountName>{accountName}</AccountName>
            <Close
              sx={{
                height: '27px',
                width: '27px',
                cursor: 'pointer',
              }}
              onClick={toggleDrawer(false)}
            />
          </Box>
          <Divider />
          <List>
            {topLinks.map((link, index) => {
              const { to, href } = link;
              const headerLinkProps = to ? { to } : { href, target: '_self' };
              return (
                <ListItem key={`${link.label}${index}`}>
                  <SideMenuLink
                    onClick={() => handleActionLinkClick(link)}
                    {...headerLinkProps}
                  >
                    {link.label}
                  </SideMenuLink>
                </ListItem>
              );
            })}
          </List>
          <Divider />
          {isLoggedIn && (
            <>
              <List>
                <ListItem>
                  <CardSelect
                    sx={{
                      marginLeft: '-14px',
                      fontWeight: '500',
                      fontSize: 'var(--font-20)',
                      '&:focus': {
                        border: 'solid 1px #ebebeb',
                      },
                    }}
                    fullWidth
                    value={selectedCardIndex}
                    onChange={(e) =>
                      setSelectedCardIndex(parseInt(e.target.value as string))
                    }
                    IconComponent={(props) => (
                      <SelectArrowIcon
                        {...props}
                        sx={{ fontSize: 'var(--font-16)', marginRight: '6px' }}
                      />
                    )}
                  >
                    {activePlans.length > 0 && (
                      <ListSubheader key={'activePlansSubHead'}>
                        Active
                      </ListSubheader>
                    )}
                    {activePlans.map((card, index) => (
                      <MenuItem key={`activeCard${index}`} value={index}>
                        {card.cardLabel}
                      </MenuItem>
                    ))}
                    {inactivePlans.length > 0 && (
                      <ListSubheader key={'inactivePlansSubHead'}>
                        Inactive
                      </ListSubheader>
                    )}
                    {inactivePlans.map((card, index) => (
                      <MenuItem
                        key={`inactiveCard${index}`}
                        value={activePlansCutoffIndex + index}
                      >
                        {card.cardLabel}
                      </MenuItem>
                    ))}
                  </CardSelect>
                </ListItem>
                {profilesList.map((profile, index) => (
                  <ListItem key={`profileLink${index}`}>
                    <ProfileLink
                      onClick={() => handleOnProfileChangeClick(profile)}
                    >
                      {profile.FirstName} {profile.LastName}
                    </ProfileLink>
                  </ListItem>
                ))}
              </List>
              <Divider />
            </>
          )}
          <List>
            {bottomLinks.map((link, index) => (
              <ListItem key={`supportLink${index}`}>
                <SideMenuLink
                  onClick={() => handleActionLinkClick(link)}
                  to={link.to}
                >
                  {link.label}
                </SideMenuLink>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
