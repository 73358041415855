import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DollarIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="25" height="24" viewBox="0 0 25 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-42 -480) translate(18 458.5) translate(25.25 22.989)">
              <circle
                cx="11.229"
                cy="10.771"
                r="10.495"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M11.867 17.888V16.27c.528-.044 1.007-.154 1.436-.33.429-.176.797-.402 1.105-.677.308-.275.547-.596.718-.965.17-.368.256-.778.256-1.23v-.032c0-.88-.284-1.584-.85-2.112-.567-.528-1.477-.952-2.73-1.27V6.485c.384.088.764.223 1.138.405.374.181.742.404 1.105.668l.924-1.551c-.462-.341-.946-.616-1.452-.825-.506-.21-1.056-.352-1.65-.43v-.99h-1.435v.958c-.517.044-.988.151-1.411.322-.424.17-.79.39-1.097.66-.308.27-.545.588-.71.957-.165.368-.247.772-.247 1.212v.033c0 .462.071.87.214 1.221.143.352.36.663.652.933.291.27.657.503 1.097.7.44.199.963.375 1.568.529v3.25c-.54-.088-1.04-.255-1.502-.503-.462-.247-.93-.564-1.402-.949l-1.056 1.518c.56.451 1.168.814 1.823 1.09.654.274 1.345.456 2.07.544v1.65h1.436zm-1.37-8.58c-.318-.1-.58-.2-.783-.305-.204-.105-.366-.22-.487-.347-.12-.126-.203-.261-.247-.404-.044-.143-.066-.303-.066-.479v-.016c0-.363.132-.668.396-.916.264-.247.66-.399 1.188-.454v2.92zm1.304 5.296v-3.003c.638.187 1.07.402 1.295.644.226.242.339.544.339.907v.033c0 .396-.14.718-.421.966-.28.247-.685.398-1.213.453z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
