import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import React from 'react';
import { useThemeOptions } from './useThemeOptions';

export const ThemeProvider: React.FC = ({ children }) => {
  const themeOptions = useThemeOptions();
  return (
    <MuiThemeProvider theme={createTheme(themeOptions)}>
      {children}
    </MuiThemeProvider>
  );
};
