import * as R from 'ramda';
import {
  EnvironmentSettings,
  RuntimeEnvironmentSettings,
} from '../types/environmentSettings';
import { DAW_CODES } from 'src/misc/constants';

// Use "require" for legacy 8.1.7 version of Contentful API
const contentful = require('contentful');

// ID assigned via Contentful modernPortalClientBranding entries
const CONTENTFUL_CLIENT_ID_MAP: Record<string, string> = {
  myrx: 'Nk6ek7z4YJ0XuReHb7HII',
  chronovocare: '7oNXvxHB5jCJnO6RSn0lQx',
  rxmyway: '1H9VsUWp6BVFXOkYkHr2rL',
  vividclearrx: 'kt70Mf7MmYmK94rhG2SyC',
  verusrx: '12oxhy7302ReIP1EY8VQVj',
  emsanarx: '7b514vwNH7AZaxRqd7me18',
  oreadrx: '6oIA3qknHHFXGSdqbSvNC0',
  truerx: '7sMgT3FXuellR0iraG5D88',
  pcarx: '2kOVuSCDPedMczGd6h3yz',
  nkch: '7h7Mrjvd9ugDG5XvYA05b4',
  costplusdrugs: '7j4QriwzB5ivYS3ILDvf67',
  test: '7D0UyRiHObL0KMZTQKFujn',
  demo: '49Vbu2p3ho5GNijNySf1ze',
  clearwaverx: '4PBVV1qsly2JGhGTnkPGCa',
  affirmedrx: '2z8TY6fo9aoGznj5Emh4EO',
  scriptwell: 'cvM9OcuGpl8Qe5R5K6gSw',
  pillowph: '02YwqNIkZ6ciRLw53Jqmk',
  avantarx: 'KlXOv0Eb6QCmX9FYTAoCE',
  fscca: '2hgz5ZHgFVjDixUhYDd8iV', //fairscript,
  fourcornershealth: '5VX1SrieQfcOBw8bpd0ol8', //fch
};

export const GET_CLEARWAVE_ID = () => CONTENTFUL_CLIENT_ID_MAP['clearwaverx'];

export const getSuitableDawCode = (): number => {
  const clientBrandingId = getClientBrandingId();
  return clientBrandingId === GET_CLEARWAVE_ID()
    ? DAW_CODES.SUBSTITUTION_NOT_ALLOWED
    : DAW_CODES.WITH_DIFFERENTIAL_PENALTY;
};

// If no client ID, default to MyRxPlan
export const getContentfulClientKey = (clientId: string) =>
  CONTENTFUL_CLIENT_ID_MAP[clientId] || CONTENTFUL_CLIENT_ID_MAP['myrx'];

const getHostname = () => {
  return window ? window.location.hostname : false;
};

export const getClientIdFromSubdomain = (domain = getHostname()) => {
  // The following logic allows us to apply default branding locally or mock
  // specific client branding by passing in a "REACT_APP_BRANDING_TEST_CLIENT" on .env
  if (
    window.location.href.includes('localhost') &&
    process.env.REACT_APP_BRANDING_TEST_CLIENT
  ) {
    return process.env.REACT_APP_BRANDING_TEST_CLIENT;
  }

  if (
    (window.location.href.includes('.internal.') ||
      window.location.href.includes('localhost')) &&
    window.location.search
  ) {
    // specific client branding by passing in a "testClient" query paramater
    try {
      const params = new URLSearchParams(window.location.search);

      return params.get('testClient') || params.get('testclient') || '';
    } catch (e) {
      console.error(e);
    }
  }
  if (!domain) {
    return '';
  }
  const hostnameParts = (domain as string).split('.');

  // Handles localhost / Jest tests / other odd configurations of domains
  if (hostnameParts.length < 3) {
    return '';
  }
  if (hostnameParts[1] === `internal`) {
    return '';
  }
  const [subdomain] = hostnameParts;
  if (subdomain && subdomain !== 'www') {
    return subdomain;
  }
  return '';
};

// Initialize the Contentful client
export const getContentfulClient = (
  settings: EnvironmentSettings | null,
  runtimeSettings: RuntimeEnvironmentSettings | null,
) => {
  if (
    runtimeSettings?.REACT_APP_CONTENTFUL_SPACE &&
    runtimeSettings?.REACT_APP_CONTENTFUL_HOST &&
    settings?.ContentfulApiKey
  ) {
    return contentful.createClient({
      space: runtimeSettings.REACT_APP_CONTENTFUL_SPACE,
      accessToken: settings.ContentfulApiKey,
      host: runtimeSettings.REACT_APP_CONTENTFUL_HOST,
    });
  }
  return null;
};

export const getClientBrandingId = R.compose(
  getContentfulClientKey,
  getClientIdFromSubdomain,
);
