import { useMutation } from 'react-query';
import type { UseMutationOptions } from 'react-query';
import type { ApiError } from '../../types/api';
import type { UseQueryFn } from './createUseQueryHook';

export type UseMutationFn<
  TQueryFnData = unknown,
  TVariables = undefined,
> = UseQueryFn<TQueryFnData, TVariables>;

export type CreateUseMutationHookConfig<
  TQueryFnData = unknown,
  TVariables = undefined,
> = {
  useMutationFn: UseMutationFn<TQueryFnData, TVariables>;
};

type UseMutationHookConfig<
  TQueryFnData = unknown,
  TVariables = undefined,
  TData = TQueryFnData,
  TError = ApiError,
> = {
  options?: UseMutationOptions<TQueryFnData, TError, TVariables, TData>;
};

/**
 * It creates useMutation hook in a convenient way
 * @param config - configuration
 * @example
 * ```
  export const useCreateCardMutation = createUseMutationHook({
    useMutationFn: useMutationFnGet,
  });

  const useMutationFnGet: UseMutationFn<
    Response,
    Variables
  > = () => {
    const request = useRequestMemberAPI<Response>({
      method: 'POST',
      url: apiUrls.cards,
      withAuthorization: true,
    });

    return ({ variables }) =>
      request({
        params: variables,
      });
  };
 * ```
 */
export const createUseMutationHook = <
  TQueryFnData = unknown,
  TVariables = undefined,
  TError = ApiError,
>({
  useMutationFn,
}: CreateUseMutationHookConfig<TQueryFnData, TVariables>) => {
  return <TData = TQueryFnData>({
    options,
  }: UseMutationHookConfig<TQueryFnData, TVariables, TData, TError> = {}) => {
    const mutationFn = useMutationFn();

    return useMutation<TQueryFnData, TError, TVariables, TData>(
      (variables) => mutationFn({ variables }),
      options,
    );
  };
};
