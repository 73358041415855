import { HeaderActionLink } from './types';
import { useBranding } from 'src/providers/ClientBrandingProvider';
import { MemberBenefit } from 'src/types/member';

/**
 * @param brandingData: ClientBranding
 * @returns an array of label and links to display in header
 */
export const useTopHeaderLinks = (): HeaderActionLink[] => {
  const brandingData = useBranding();
  const brandingLinks = brandingData?.headerfooterLinkUrls?.map(
    (url, index) => {
      const urlProp = url[0] === '/' ? { to: url } : { href: url };
      return {
        ...urlProp,
        label: brandingData?.headerfooterLinkTexts![index] || url,
      };
    },
  );
  const links = brandingLinks || [];
  return [{ label: 'Home', to: '/' }, ...links];
};

export const mapMemberBenefitToMenuBenefitCard = (
  memberBenefit: MemberBenefit,
) => {
  const {
    Members = [],
    MemberNumber = '',
    GroupName,
    MemberCard,
  } = memberBenefit;
  const cardId = (MemberCard?.MemberCardId || '').toString();
  return {
    cardId,
    cardLabel: `${GroupName} ${MemberNumber}`,
    profiles: Members,
  };
};
