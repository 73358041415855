import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SelectArrowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    focusable="false"
    aria-hidden="true"
    width="15px"
    height="9px"
    viewBox="0 0 15 9"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-874.000000, -176.000000)"
        stroke="currentColor"
        strokeWidth="2"
      >
        <g transform="translate(881.500000, 180.500000) scale(1, -1) translate(-881.500000, -180.500000) translate(875.500000, 177.500000)">
          <polyline
            transform="translate(6.000000, 3.000000) scale(1, -1) translate(-6.000000, -3.000000) "
            points="0 0 6 6 12 0"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
