import { useState } from 'react';
import { useIdleTimer, EVENTS } from 'react-idle-timer';
import { useTimeout } from '../../hooks/useTimeout';
import { useAuth } from '../Auth';

const events: EVENTS[] = [
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];

const isTestEnv = process.env.NODE_ENV === 'test';

const warningTimeoutDefault = 1000 * 60 * 10;
const logoutTimeoutDefault = 1000 * 60 * 5;

export type UseAutoLogoutOptions = {
  warningTimeout?: number;
  logoutTimeout?: number;
};

/**
 * It returns information when warning modal should be shown and do user logout after additional time
 * @param warningTimeout when modal should be shown
 * @param logoutTimeout when user should be logged out
 * @returns 
 ```
  {
    reset - function that reset warning and logout timer,
    showWarning - boolean that indicates whether to show a modal warning
  }
 ```
 */
export const useAutoLogout = ({
  warningTimeout = warningTimeoutDefault,
  logoutTimeout = logoutTimeoutDefault,
}: UseAutoLogoutOptions = {}) => {
  const { logout, isLoggedIn } = useAuth();
  const [showWarning, setShowWarning] = useState(false);

  const { reset, pause } = useIdleTimer({
    timeout: warningTimeout,
    startOnMount: isLoggedIn,
    crossTab: !isTestEnv && {
      emitOnAllTabs: true,
    },
    events,
    onIdle: () => {
      setShowWarning(true);
      pause();
    },
    onActive: () => {
      setShowWarning(false);
    },
  });

  useTimeout(
    () => {
      const doLogout = async () => {
        await logout();
      };
      doLogout();
    },
    showWarning ? logoutTimeout : undefined,
  );

  return { reset, showWarning };
};
